import MODALS from 'shared/constants/Modals';
import reinjectScripts from 'lib/utils/ReinjectScripts';

export default function(store){
    const POPUP_FREQ = 3;
	store.subscribe((mutation, state) => {
		if(mutation.type === 'ads/setPopupAd' && mutation.payload.hasOwnProperty('src') && mutation.payload.src.length > 0){
			const storageCounter = window.localStorage.getItem('popupAdCounter');
			const counter = storageCounter === null ? 0 : parseInt(storageCounter);
			store.commit('ads/adsAssign', { popupAdCounter: counter });
			if(counter === 0){
                store.commit('openModal', MODALS.POPUP_AD);
            }
            const newCounter = (counter + 1) % POPUP_FREQ;
			window.localStorage.setItem('popupAdCounter', newCounter);
		}
        /** 
         * Because advertisers may want to include their own tracking scripts
         * we can't render the ad html within Vue components, since attached scripts will not load. 
         * 
         * Here, we render the HTML inside container divs. 
         * Ads loaded through the API are currently still in vue components, which is ok since
         * the Javascript will already be loaded above.
        */
        else if(mutation.type === 'ads/adsAssign'){
            if(mutation.payload.hasOwnProperty('leaderboardAd') && mutation.payload.leaderboardAd !== ''){
                const $leaderboard = document.getElementById('leaderboard-ad');
                $leaderboard.innerHTML = mutation.payload.leaderboardAd;
                reinjectScripts($leaderboard);
                $leaderboard.classList.add('leaderboard-ad');
                store.commit('ads/adsAssign', { leaderboardHeight: $leaderboard.offsetHeight });
            }

            if(mutation.payload.hasOwnProperty('bannerAds') && !!mutation.payload.bannerAds.length){
                const $banners = document.getElementsByClassName('js-banner-ad');
                const bannerAds = mutation.payload.bannerAds;
                for(let i = 0; i < $banners.length; i++){
                    let $banner = $banners.item(i);
                    let bannerAd = bannerAds[i % bannerAds.length];
                    $banner.innerHTML = bannerAd;
                    reinjectScripts($banner);
                    $banner.classList.add('banner-ad');
                }
            }
        }
	})
}