<template>
	<span></span>
</template>

<script>
	import loadMore from './mixins/LoadMore';
	import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
	export default {
		computed: {
			...mapGetters('viewport', ['scrollBottom'])
		},
		
		watch: {
			scrollBottom: function(scrollBottom){
				if( ( scrollBottom >= this.$el.offsetTop ) && ( !this.isActive ) ) {
					this.loadMore();
				}
			}
		},

		mixins: [ loadMore ]
	};
</script>