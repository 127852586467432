<template>
    <li>
        <article class="article-list__article">
            <header>
                <div v-if="showChannel" class="slug--single-article">
                    <a :href="channelLink">{{article.siteSectionLabel}}</a>
                </div>
                <div class="article-list__primary-image">
                    <div class="image-container image-container--2x1" v-if="typeof article.media !== 'undefined'">
                        <a :href="article.url">
                            <img v-if="article.media.path_large" :srcset="srcset(article.media)" :src="`${IMAGE_BASE_PATH}${article.media.path_large}`" :sizes="sizes" :alt="article.media.caption_formatted | stripTags">
                            <img v-else-if="typeof article.media !== 'undefined'" :srcset="srcset(article.media)" :src="`${IMAGE_BASE_PATH}${article.media.path}`" :alt="article.titleFormatted| stripTags">
                        </a>
                    </div>
                </div>
                <h1 class="article-list__h1">
                    <a :href="article.url" v-html="article.titleFormatted"></a>
                </h1>
                <h2 v-if="article.lead">
                    {{ article.leadFormatted }}
                </h2>
                <div class="article-list__article-meta">
                    <span class="article-list__publish-date" v-if="!article.issueTitle">
                        {{ article.published | formatDate('YYYY.MM.DD') }}
                    </span> 
                    <span class="article-list__publish-date" v-if="article.issueTitle">
                        <strong>PRINT</strong> {{ article.issueTitle }}
                    </span>
                    <span v-if="article.writer" class="article-list__byline">
                         • <span v-html="article.writerFormatted"></span>
                    </span>
                </div>
            </header>
               <div class="article-list__content" v-html="article.bodyExcerpt"></div>
            <div class="article-list__read-more">
                <a class="article-list__read-more-link" :href="article.url">
                    阅读全文
                </a>
            </div>
        </article>
    </li>
</template>
<script>
    import article from './mixins/article';
    
    export default {
        name: 'list-article',
        mixins: [article]
    };
</script>