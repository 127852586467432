import localConfig from '../../../localConfig';

/* local configs*/
const SITE_URL = localConfig.baseUrl;
const WEBROOT = localConfig.webroot;
const IMAGE_BASE_PATH = localConfig.imageBasePath;

/* public website paths */
const SITE_PATH = SITE_URL + WEBROOT;
const API_PATH = SITE_URL + WEBROOT + '/api';

/* admin paths */
const ADMIN_API_PATH = SITE_URL + WEBROOT + '/api/admin';
const ADMIN_SITE_PATH = SITE_URL + WEBROOT + '/admin';

const BOOKFORUM_URL = localConfig.bookforumUrl;
const ARTFORUM_API_URL = localConfig.artforumApiUrl;

export { 
    SITE_URL,
    WEBROOT,
    IMAGE_BASE_PATH,
    SITE_PATH,
    API_PATH,
    ADMIN_API_PATH,
    ADMIN_SITE_PATH,
    BOOKFORUM_URL,
    ARTFORUM_API_URL,
};