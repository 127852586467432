import { API_PATH }from 'shared/constants/Paths';

const relatedArticles = {
	namespaced: true,

	state: {
		relatedArticles: [],
	},

	mutations: {
		setRelatedArticles(state, articles){
            state.relatedArticles = articles;
        },
	},

	actions: {
		getRelatedArticles: function({ commit }, articleId){
            const _url = `${API_PATH}/items/${articleId}/related`;
            
            const headers = new Headers({
                'Accept': 'application/json'
            });

            fetch(_url, {
                headers: headers
            }).then( (res) => {
                if(res.ok){
                    return res.json();
                }
                throw new Error(res.statusText);
            }).then((data)=>{
                commit('setRelatedArticles', data._embedded.items);
            }).catch((err) => {
                console.warn('Related articles: ', err);
            })
        },
	}
};

export default relatedArticles;