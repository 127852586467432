/**
 * Get the value of a query parameter from a url query string
 *
 * @param {String} name - the name of the parameter to get
 * @param {String} url - the url to search for the param
 * @return {Mixed} 
 */

export default function(name, url) {
    if (!url) {
      url = window.location.href;
    }
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}