<template>
	<a class="hp-news__article" :href="article.url">
		<div class="image-container image-container--1x1">
		    <div v-if="article.media && article.media.path_square">
	            <img class="hp-bloglist__image" :srcset="srcsetComputed" :sizes="sizes" :src="srcComputed" :alt="article.titleFormatted | stripTags">
            </div>
            <div v-else-if="article.media">
			    <img class="hp-bloglist__image" :srcset="srcsetComputed" :sizes="sizes" :src="srcComputed" :alt="article.titleFormatted | stripTags">
            </div>
		</div>
		<div class="hp-news__date">{{ article.published | formatDate('YYYY.MM.DD') }}</div>
		<h3 class="hp-news__title" v-html="article.titleFormatted"></h3>
	</a>
</template>

<script>
	import formatDate from 'shared/components/mixins/FormatDate';
	import { BREAKPOINT_MEDIUM } from 'shared/constants/Common.js';
	import srcsetFromMedia from 'shared/components/mixins/SrcsetFromMedia';
	import stripTags from 'shared/components/mixins/StripTags';

	export default {
		props: {
			article: Object,
			index: Number,
			slidesPerScreen: Number,
			slideIndex: Number
		},
		computed: {
		    queryMobile: () => '(max-width: ' + BREAKPOINT_MEDIUM + 'px)',
		    queryDesktop: () => '(min-width: ' + BREAKPOINT_MEDIUM + 'px)',
		    loadImg: function(index){
				return this.index <= ( this.slideIndex + 1 ) * this.slidesPerScreen;
			},
		    srcsetComputed: function(){
		    	return this.loadImg ? this.srcset(this.article.media) : '';
		    },
		    srcComputed: function(){
		    	return this.loadImg ? this.article.media.path : '';
		    }
		},

		mixins: [formatDate, srcsetFromMedia, stripTags]
	};
</script>

<style lang="scss">
	@import "scss/variables/typography";
	
	.hp-news__date{
		font-size: 1rem;
		font-weight: $book;
		margin: .5rem 0px;
	}
	.hp-news__title{
		margin: 0px;
		text-transform: uppercase;
		font-size: 1.125rem;
		font-weight: $medium;
	}
</style>
