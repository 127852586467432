import { ARTICLES_PER_PAGE } from 'shared/constants/Common';
import { mapState, mapActions, mapMutations } from 'vuex';
import pruneObject from 'lib/utils/PruneObject';

export default {
    props: {
        category: {
            type: String,
            required: false
        },
        tag: {
            type: String,
            required: false
        },
        storePaged: {
            type: Boolean,
            required: false,
            default: true
        },
        pageSize: {
            type: Number,
            default: ARTICLES_PER_PAGE,
            required: false
        },
        startPage: {
            type: Number,
            default: 1,
            required: false
        },
        excludeId: {
            type: Number,
            requrired: false
        },
    },
    // data() {
    //     return {
    //         associatedAction: 'loadMoreArticles'
    //     }
    // },

    computed: {
        ...mapState({
            paged: (state) => state.articles.paged,
            activeCalls: (state) => state.activeCalls,
            allPagesLoaded: (state) => state.articles.allPagesLoaded
        }),
        isActive: function(){
            return this.associatedAction ? this.activeCalls.indexOf(this.associatedAction) > - 1 : false;
        },
        associatedAction: function(){
            return this.category === 'video' ? 'loadMoreVideos' : 'loadMoreArticles';
        }
    },

    methods: {
        ...mapActions('articles', ['loadMoreArticles', 'loadMoreVideos']),
        ...mapMutations('articles', ['incrementPaged', 'articlesAssign']),
        loadMore: function(){
            if ( this.isActive ) return;

            const options = {
                category:  this.category,
                page:      this.paged,
                limit:     this.limit,
                itemType:  this.itemType,
                tag:       this.tag,
                excludeId: this.excludeId,
                storePaged: this.storePaged
            };
            // remove undefined properties
            pruneObject(options);
            if(this.category === 'video'){
                this.loadMoreVideos(options);
                return;
            }
            this.loadMoreArticles(options);
        }
    },

    mounted: function(){
        this.articlesAssign({paged: this.startPage});
    }
}