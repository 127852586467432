import { BOOKFORUM_URL } from 'shared/constants/Paths';

const bookforum = {
	namespaced: true,

	state: {
		homepageFeature: {},
		homepageArticles: {
			print: [],
			web: []
		}
	},

	mutations: {
		bookforumAssign(state, data){
			Object.assign(state, data);
		},
	},

	actions: {
		getBookforumHomepageData: function({ commit }){
			const BOOKFORUM_API_URL = `${BOOKFORUM_URL}/api/items/artforum`;
			const headers = new Headers({
                'Accept': 'application/json',
            });
			commit('addActiveCall', 'getBookforumHomepageData', { root: true });
			fetch(BOOKFORUM_API_URL, {
				headers: headers
			}).then((res) => {
				if(!res.ok){
					throw new Error('could not fetch bookforum data');
				}
				try{
					return res.json();
				}
				catch(e){
					console.log(e);
				}
			}).then((data) => {
				commit('removeActiveCall', 'getBookforumHomepageData', { root: true });
				const { print, web, cover } = data;
				commit('bookforumAssign', { homepageFeature: cover, homepageArticles: { print, web } });
			}).catch((error) => {
				commit('removeActiveCall', 'getBookforumHomepageData', { root: true });
				console.warn("failed to fetch bookforum data", error);
			})
		}
	}
}

export default bookforum;