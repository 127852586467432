<template>
	<div class="slider lory__slider carousel__slider js_simple_dots js_slider">
	  <div class="frame lory__frame carousel__frame js_frame">
		<ul class="slides lory__slides carousel__slides js_slides">
			<li v-for="slide in slides" class="js_slide carousel__slide lory__slide">
			<img class="carousel__img" :src="siteUrl + slide.pathLarge" :alt="altText(slide)" />
				<div v-if="slide.captionFormatted !== undefined" class="carousel__caption">
					<p v-html="slide.captionFormatted"></p>
				</div>
				<div v-else class="carousel__placeholder-caption">
					<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
				</div>
			</li>
		</ul>
	  </div>
	  
	  <span class="js_prev prev">
		  <img class="slider__arrow" src="~img/arrow_black_left.svg">
	  </span>
	  <span class="js_next next">
		  <img class="slider__arrow" src="~img/arrow_black_right.svg">
	  </span>

	  <ul class="js_dots dots">
	  	<li v-for="(slide, index) in slides" :class="{active: activeDot(index)}"></li>
	  </ul>
	</div>
</template>

<script>
	import { lory } from 'lory.js';
	import NAVIGATION from 'shared/constants/Navigation';
	import stripTags from 'lib/utils/StripTags';

	export default {
		props: {
			slidesJson: String
		},

		data(){
			return {
				slides: [],
				slider: {},
				slideIndex: 0,
				siteUrl: NAVIGATION.SITE_URL
			}
		},

		methods: {
			activeDot(index){
				return index === this.slideIndex;
			},

			altText(slide){
				return slide.captionFormatted !== undefined ? stripTags(slide.captionFormatted) : '';
			}	
		},

		mounted: function(){
			this.$el.addEventListener('after.lory.slide', (e) => {
				this.slideIndex = e.detail.currentSlide;
			});

			const p = new Promise( (resolve, reject) => {
				try {
					resolve( JSON.parse(this.slidesJson) );
				}
				catch(e){
					reject(e);
				}
			}).then((slides) => {
				this.slides = slides;

				this.$nextTick(function(){
					this.slider = lory(this.$el, {
						infinite: false,
                		enableMouseEvents: true,
                		rewindOnResize: false,
					});
				})
			}).catch((err) => {
				console.warn(err);
			})
		}
	};
</script>
