<template>
	<transition name="fade">
		<div v-if="lightboxIsVisible" class="lightbox overlay">
			<div class="lightbox__header" :class="{'lightbox__header--hiddenMobile': scrolledDown}">
				<img class="lightbox__logo" src="~img/artforum-logo.svg" />
				<!-- <h4 class="lightbox__title" v-html="title"></h4> -->
				<button class="overlay__collapse" v-on:click.prevent="closeModal"></button>
			</div>
			<div v-on:scroll="onScroll" class="lightbox__wrapper">
				<div>
					<figure v-for="(image, index) in lightboxImages" class="lightbox__content" :class="{'lightbox__content--visible': index === lightboxCurrentIndex }">
						<img class="lightbox__image" v-on:click="lightboxNextImage" :src="fullImagePath(image.path_large)">
						<caption class="lightbox__caption" v-html="image.caption_formatted"></caption>
						<!-- <div v-if="lightboxImages.length > 1" class="lightbox__count">{{index + 1}} of {{lightboxImages.length}}</div> -->
					</figure>
					<nav v-if="hasManyImages" class="lightbox__navigation">
						<a href="#" class="lightbox__nav-link" v-on:click.prevent="lightboxPreviousImage"><img class="lightbox__nav-image" src="~img/arrow_black_left.svg" alt="previous image"></a>
						<span class="lightbox__indicator">{{ lightboxCurrentIndex + 1 }} / {{ lightboxImages.length }}</span>
						<a href="#" class="lightbox__nav-link" v-on:click.prevent="lightboxNextImage"><img class="lightbox__nav-image" src="~img/arrow_black_right.svg" alt="next image"></a>
					</nav>
				</div>
			</div>
		</div>
	</transition>
</template>
<script>
	import { mapState, mapGetters, mapMutations, mapActions  } from 'vuex';
	import scrollDirection from '../mixins/ScrollDirection';
	import { IMAGE_BASE_PATH } from 'shared/constants/Paths';

	export default {
		mixins: [scrollDirection],
		props: ['articleId'],
		computed: {
			...mapState({
				lightboxImages: state => state.lightbox.lightboxImages,
				lightboxCurrentIndex: state => state.lightbox.lightboxCurrentIndex,
				lightboxCurrentId: state => state.lightbox.lightboxCurrentId,
				title: state => state.lightbox.title,
				isMobile: state => state.viewport.isMobile,
			}),
			...mapGetters([
				'lightboxIsVisible'
			]),
			...mapGetters('viewport', ['isMobile']),
			firstImageIsSelected(){
				return this.lightboxCurrentIndex == 0;
			},
			lastImageIsSelected(){
				let lastIndex = this.lightboxImages.length - 1;
				return this.lightboxCurrentIndex >= lastIndex;
			},
			hasManyImages(){
				return this.lightboxImages && this.lightboxImages.length > 1;
			},
		},
		methods: {
			...mapMutations('lightbox', [
				'lightboxNextImage',
				'lightboxPreviousImage',
				'lightboxSetIndex'
			]),

			...mapMutations(['closeModal']),

			...mapActions('lightbox', ['getArticleImages']),

			scrollToCurrentImage(){
				setTimeout(() => {
					this.$el.getElementsByClassName('lightbox__content--visible')[0].scrollIntoView();
				}, 100)
			},

			fullImagePath(path){
				return `${IMAGE_BASE_PATH}${path}`;
			},
		},
		watch: {
			lightboxIsVisible: function(isVisible){
				if(isVisible){
					this.$nextTick(() => {
						this.scrollToCurrentImage();
					});
				}
			}
		},
	};
</script>
<style lang="scss">
	@import "scss/variables/breakpoints";
	@import "scss/variables/typography";
	@import "scss/transitions/fade";

	.lightbox__header{
		position: fixed;
		padding: 5%;
		width: 90%;
		background: #fff;
		transition: transform .5s ease;
		top: 0;
		z-index: 1;
	}

	.lightbox__header--hiddenMobile{
		transform: translate(0, -100%);
	}

	.lightbox__logo{
		height: 1.5rem;
	}

	.lightbox__title{
		display: inline;
		margin: 0;
		padding: 0;
		font-size: 1.125rem;
		// margin-left: 1em;
		text-transform: uppercase;
		font-weight: normal;
	}

	.lightbox__wrapper{
		overflow: scroll;
		// touch scrolling is breaking the scroll for some reason...
		-webkit-overflow-scrolling: touch;
		height: 100vh;
		padding-bottom: 3em;
	}

	.lightbox__content{
		max-width: 800px;
		margin: 0 auto;
		text-align: left;
		margin-top: 60px;
	}

	.lightbox__content--visible{
	}

	.lightbox__image{
		width: 100%;
		object-fit: contain;
		max-height: 90vh;
	}

	.lightbox__caption{
		color: #000;
		display: inline-block;
		text-align: left;
		font-size: .875rem;
		line-height: 1rem;
		padding: 0px 5%;
		margin-top: .75rem;
	}

	.lightbox__navigation{
		text-align: right;
		display: none;
	}

	.lightbox__nav-link{
		margin: 0 .5rem;
	}

	.lightbox__nav-link--disabled{
		opacity: .5;
	}

	.lightbox__nav-image{
		height: 1rem;
	}

	.lightbox__count{
		text-align: right;
		padding-right: 5%;
	}

	@media screen and (max-width: $breakpoint-medium){

		.lightbox{
			-webkit-overflow-scrolling: touch;
			background: #fff;
		}

		.lightbox__content{
			margin-top: 30px;
		}

		.lightbox__content:first-of-type{
			margin-top: 92px;
		}

		.lightbox__content:last-of-type{
			padding-bottom: 3em;
		}

		.lightbox__collapse{
			float: right;
		}
	}

	@media screen and (min-width: $breakpoint-medium){
		.lightbox{
			display: table;
		}

		.lightbox__wrapper{
			display: table-cell;
			vertical-align: middle;
		}

		.lightbox__logo{
			display: none;
		}

		.lightbox__title{
			display: none;
		}

		.lightbox__collapse{
			position: absolute;
			top: 2em;
			right: 2em;
		}
		.lightbox__header{
			background: transparent;
		}

		.lightbox__content{
			display: none;
			max-height: 90vh;
		}
		.lightbox__content--visible{
			display: table;
		}
		.lightbox__image{
			width: auto;
			max-width: 100%;
			max-height: 100%;
			display: block;
			margin: 0 auto;
			max-height: 80vh;
		}
		.lightbox__caption{
			padding: 0px;
			display: table-caption;
			caption-side: bottom;
			min-height: 4em;
		}
		.lightbox__navigation{
			display: block;
			position: absolute;
			bottom: 5rem;
			right: 3em;
			font-size: 1.25rem;
		}
		.lightbox__count{
			display: none;
		}
	}
</style>