import { BREAKPOINT_MEDIUM } from 'shared/constants/Common';
import { IMAGE_BASE_PATH } from 'shared/constants/Paths';

const buildSrcSet = (set)=>{
	let srcset = '';
	try{
		for (let key in set ) {
			if (set.hasOwnProperty(key)){
				srcset = `${srcset}${IMAGE_BASE_PATH}${set[key]} ${key}w, `;
			}
		}
	}
	catch (e){
		console.warn(e);
	}
	return srcset;
}

export default {
	props: {
		preferredImgCrop: {
			type: String,
			required: false,
			default: 'original'
		},
		desktopImgSize: {
			type: Number,
			required: false,
			default: 810
		}
	},

	data(){
		return {
			breakpointMedium: BREAKPOINT_MEDIUM,
			IMAGE_BASE_PATH: IMAGE_BASE_PATH,
		}
	},

	computed: {
		sizes: function(){
			return `(min-width: ${this.breakpointMedium}px) ${this.desktopImgSize}px`
		}
	},
	
	methods: {
		srcset: function(media, preferredCrop = this.preferredImgCrop){

			const crops = media.crops;

			if(crops.hasOwnProperty(preferredCrop)){
				return buildSrcSet(crops[preferredCrop]);
			}

			else if(crops.hasOwnProperty('original')){
				return buildSrcSet(crops['original']);
			}

			else return media.path_large ? `${this.IMAGE_BASE_PATH}${media.path_large}` : `${this.IMAGE_BASE_PATH}${media.path}`;
		}
	}
}