<template>
    <article class="hp-bloglist__article">
        <div v-if="index === 0" class="image-container image-container--2x1 image-container--1x1-mobile mobile-full-width">
            <img>
        </div>
        <div>
            <div class="hp-bloglist__title">
                <dot-dot-dot associated-action="getSectionFeaturedArticles"></dot-dot-dot>
            </div>
        </div>
    </article>
</template>

<script>
    import dotDotDot from '../animations/DotDotDot.vue';
    export default {
        props: {
            index: {
                required: false,
                type: Number,
                default: 0
            },
        },
        components: {dotDotDot}
    };
</script>