module.exports = {
    baseUrl: '//www.artforum.com.cn',
    imageBasePath: '//www.artforum.com.cn',
    webroot: '',
    artforumApiUrl: 'https://www.artforum.com/api',
    apiKeys: {
        RECAPTCHA_API_KEY: '6LfAi9sUAAAAACWj2M2YHzxWdJNyFSr92xSeGAug',
        GOOGLE_ANALYTICS_TID: 'UA-80108-8',
        GOOGLE_MAPS_API_KEY: 'AIzaSyB2zCv_96w-RcZ2JUyW2TwGWmhjMNA6HSY'
    }
};
