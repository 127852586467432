const MODALS = {
	SLIDE_MENU: 'SLIDE_MENU',
	SEARCH: 'SEARCH',
	MOBILE_SEARCH: 'MOBILE_SEARCH',
	LIGHTBOX: 'LIGHTBOX',
	REGISTER_FORM: 'REGISTER_FORM',
	LOGIN_FORM: 'LOGIN_FORM',
	POPUP_AD: 'POPUP_AD',
}

export default MODALS;