<template>
	<form class="form">
		<slot v-if="!passwordRecoverySent"></slot>
		<p v-if="errorMessage.length" role="alert" class="form__message--error">{{ errorMessage }}</p>
		<p class="success-message form__message--emphasized" v-if="passwordRecoverySent">A confirmation e-mail has been sent to your e-mail address. Please follow the instructions to reset your password. If you are still experiencing issues please contact us at circulation@artforum.com.</p>
		<div v-if="!passwordRecoverySent">
			<label for="forgot_password_email" class="visually-hidden">email</label>
			<input id="forgot_password_email" class="form__text-input" type="text" placeholder="e-mail" name="email" v-model="userEmail" />
			<re-captcha ref="recaptcha" :callback="recaptchaCallback" id="recoveryCaptcha" style="margin: .75rem 0px 1rem 0px"></re-captcha>
			<submit-button :callback="submitForm" associated-action="requestPasswordReset" button-text="Request Recovery Email"></submit-button>
		</div>
	</form>
</template>

<script>

	import reCaptcha from './ReCaptcha.vue';
	import submitButton from 'shared/components/forms/SubmitButton.vue';
	import recaptchaIntegration from './mixins/recaptchaIntegration';
	import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';

	export default {
		data: function() {
			return{ 
				userEmail: ''
			}
		},

		computed: {
			...mapState({
				errorMessage: (state) => state.user.passwordRecoveryMessage,
				passwordRecoverySent: (state) => state.user.passwordRecoverySent,
			})
		},

		methods: {
			...mapActions('user', ['requestPasswordReset']),

			...mapMutations(['addActiveCall']),

			...mapMutations('user', ['setPasswordRecoverySent', 'setPasswordRecoveryMessage']),

			resetFields: function(){
				this.userEmail = ''
			},

			submitForm: function(){

				this.setPasswordRecoverySent(false);
				this.setPasswordRecoveryMessage('');

				if ( !this.recaptchaIsCompleted() ) {
					return false;
				}

				const options = { email: this.userEmail };

				this.addRecaptchaToRequest(options);
				this.requestPasswordReset(options)
				.then(() => {
					this.resetFields();
					this.submitted = true;
					this.$refs.recaptcha.reset();
				})
				.catch((err)=>{
					this.resetFields();
					this.$refs.recaptcha.reset();
					console.warn(err);
				});
			}
		},

		components: {
			reCaptcha,
			submitButton,
		},

		mixins: [ recaptchaIntegration ]
	}
</script>