import _reduce from 'lodash/reduce';
import isArray from 'lib/utils/IsArray';

function encodeProperty(key, val){
    if (typeof val === 'undefined') return '';
    return isArray(val) ? val.reduce((result, part) => `${result}${encodeURIComponent(key)}[]=${encodeURIComponent(part)}&`, '') : `${encodeURIComponent(key)}=${encodeURIComponent(val)}&`;
}

/**
 * Take an object and convert it to a query string
 * 
 * @param {Object} options
 * @return {String}
 */

export default function(options) {
	return _reduce(options, (result, value, key) => `${result}${encodeProperty(key, value)}`, '?');
}

// this old version may be useful if we remove lodash

// export default function toQueryString(obj) {
// 	console.log(obj);
//     var parts = [];
//     for (var i in obj) {
//         if (obj.hasOwnProperty(i)) {
//             parts.push(encodeURIComponent(i) + "=" + encodeURIComponent(obj[i]));
//         }
//     }
//     return parts.join("&");
// }

