import getQueryParameterByName from 'lib/utils/GetQueryParameterByName';
import { ARTICLES_PER_PAGE } from 'shared/constants/Common';

export default function(store){
    window.addEventListener('DOMContentLoaded', function(e){
        const paged = getQueryParameterByName('paged', window.location);
        if (paged){
            const category = store.state.navigation.category;
            

            const options = {
                category: category,
                page:     1,
                pageSize: paged * ARTICLES_PER_PAGE,
                offset: ARTICLES_PER_PAGE
            }

            if( category === 'video' ){
                options.itemType = 'video';
                options.tag = store.state.navigation.tag;
            }

            store.dispatch('articles/loadMoreArticles', options);
            store.commit('articlesAssign', {paged: paged});
        }
    });

    store.subscribe( (mutation, state) => {
        if(mutation.type === 'articles/incrementPaged' && mutation.payload.storePaged && history.replaceState){
            const currentPage = state.articles.paged - 1;
            const newUrl = `${window.location.protocol}//${window.location.host + window.location.pathname}?paged=${currentPage}`;
            history.replaceState({path: newUrl}, '', newUrl);
            
            if ( typeof window.ga !== 'undefined' ) {
                window.ga('set', 'page', window.location.pathname + window.location.search);
                window.ga('send', 'pageview', window.location.pathname + window.location.search);
            }
        }
    });
}