/**
 * remove undefined properties of an object
 *
 * @param {Object} o
 * @return {Object}
 */

export default function(o){
	for (let p in o){
		if ( typeof o[p] === 'undefined' ){
			delete o[p];
		}
	}
}