<template>
	<div class="banner-ad" v-html="articleAd"></div>
</template>
<script>
	import { mapState } from 'vuex';
	export default {
		props: {
			bannerIdx: {
				type: Number,
				required: false,
				default: 0,
			}
		},
		computed: {
			...mapState({
				bannerAds: (state) => state.ads.bannerAds
			}),
			adIdx(){
				return (this.bannerIdx) % this.bannerAds.length;
			},
			articleAd(){
				return this.bannerAds.length > 0 ? this.bannerAds[this.adIdx] : '';
			}
		}
	};
</script>
<style lang="scss">
	@import "scss/variables/grid";
	@import "scss/variables/breakpoints";

	.banner-ad{
		margin-top: 0px;
		margin-bottom: 100px;
		//text-align: center;
		height: auto;
	}

	.banner-ad img{
		height: auto;
	}

	@media screen and (max-width: $breakpoint-medium) {
		.banner-ad{
			margin-bottom: 60px;
		}
	}

	@media screen and (min-width: $breakpoint-medium) {

		.banner-ad{
			width: 100%;
			max-width: 750px;
		}

		.banner-ad .Ad{
			width: 100%;
			max-width: 750px;
		}
	}
</style>