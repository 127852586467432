<template>
	<div class="related-articles" v-if="relatedArticles.length > 0">
		<hr class="border related-articles__border mobile-full-width">
		<h3 class="related-articles__heading">相关阅读</h3>
		<desktop-carousel v-if="relatedArticles.length > 3 && !isMobile" :articles="relatedArticles"></desktop-carousel>
		<desktop-static v-if="relatedArticles.length < 4 && !isMobile" :articles="relatedArticles"></desktop-static>
		<mobile-carousel v-if="relatedArticles.length && isMobile" :articles="relatedArticles"></mobile-carousel>
		<slot></slot>
	</div>
</template>

<script>
	import DesktopCarousel from './related-articles/DesktopCarousel.vue';
	import DesktopStatic from './related-articles/DesktopStatic.vue';
	import MobileCarousel from './related-articles/MobileCarousel.vue';

	import {mapState, mapActions, mapGetters} from 'vuex';

	export default {
		computed: {
			...mapState({
				relatedArticles: (state) => state.relatedArticles.relatedArticles
			}),
			...mapGetters({
				isMobile: 'viewport/isMobile'
			})
		},
		props: ['articleId'],
		mounted: function(){
			this.$nextTick(function(){
				this.getRelatedArticles(this.articleId);
			});
		},
		methods: {
			...mapActions('relatedArticles', ['getRelatedArticles'])
		},
		components: {
			DesktopCarousel,
			DesktopStatic,
			MobileCarousel
		}
	};

</script>

<style lang="scss">
@import "scss/variables/typography";
@import "scss/variables/grid";
@import "scss/variables/breakpoints";

.related-articles{
	margin-bottom: 2rem;
}

.related-articles__border{
	background: $text-grayscale-dark;
	margin: 2rem 0px 2rem 0px;
}

.related-articles__heading{
	padding: 0;
	font-family: $sans-base-font-family;
	text-transform: uppercase;
	font-weight: normal;
	font-size: 1.375rem;
}


@media (min-width: $breakpoint-medium){

	.related-articles__heading{
		text-align: center;
	}

	.related-articles__border{
		margin: 2rem 0 2rem 0;
	}

	.related-articles__heading{
		text-align: left;
	}
}
</style>