/*
* This plugin watches the shipping country select field on the checkout form and sets the cart shipping region in the
* serverCart module.
*/

export default function(store){
	document.addEventListener('DOMContentLoaded', function(){
		const shippingCountrySelect = document.getElementById('orders_shiptoCountry');
		if ( shippingCountrySelect === null ) return false;
	
		shippingCountrySelect.addEventListener('change', function(e){
			const isDomestic = e.target.value === 'US' ? true : false;
			store.commit('serverCart/setDomesticShipping', isDomestic); 
		});
	});
}