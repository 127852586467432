const ARTICLES_PER_PAGE = 12;
const BREAKPOINT_MEDIUM = 840; //px
const BREAKPOINT_SMALL = 600; //px

const DATE_FORMAT_STRING = 'YYYY.MM.DD';

export {
	ARTICLES_PER_PAGE,
	BREAKPOINT_MEDIUM,
	BREAKPOINT_SMALL,
    DATE_FORMAT_STRING,
}