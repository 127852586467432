import _findIndex from 'lodash/findIndex';
import { API_PATH }from 'shared/constants/Paths';
import toQueryString from 'lib/utils/ToQueryString';

const lightbox = {
    namespaced: true,

    state: {
        lightboxImages:                [],
        lightboxCurrentIndex:          0,
        lightboxCurrentId:             0,
        lightboxCurrentArticle:        0,
        title:                         '',
        page:                          0,     // optional
        allPagesLoaded:                false, // optional
    },

    mutations: {
        addLightboxImages(state, images){
            state.lightboxImages = state.lightboxImages.concat(images);
        },

        lightboxNextImage(state){
            let isLastImage = state.lightboxCurrentIndex >= state.lightboxImages.length -1;
            let nextIndex = isLastImage ? 0 : state.lightboxCurrentIndex + 1; 
            state.lightboxCurrentIndex = nextIndex;
        },

        lightboxPreviousImage(state){
            let isFirstImage = state.lightboxCurrentIndex === 0;
            let lastIndex = state.lightboxImages.length - 1;
            let nextIndex = isFirstImage ? lastIndex : state.lightboxCurrentIndex - 1;
            state.lightboxCurrentIndex = nextIndex;
        },

        lightboxSetIndex(state, imageId){

            if ( !imageId ) return 0;

            const index = _findIndex(state.lightboxImages, function(image){ 
                return image.id == imageId 
            });
            state.lightboxCurrentId = imageId;
            state.lightboxCurrentIndex = index < 0 ? 0 : index;
        },

        lightboxAssign(state, data){
            Object.assign(state, data);
        },
    },

    actions: {
        getArticleImages({ commit, rootState }, payload){
            const ACTION_NAME = 'getArticleImages';

            return new Promise( (resolve, reject) => {
                
                if(rootState.activeCalls.indexOf(ACTION_NAME) > - 1){
                    reject(new Error('Image load already in progress'));
                }
    
                commit('addActiveCall', ACTION_NAME, { root: true });
                const headers = new Headers({
                    'Accept': 'application/json'
                });

                const params = {};    
                if (payload.hasOwnProperty('names')) params.names = payload.names;
                if (payload.hasOwnProperty('order')) params.order = payload.order;
                if (payload.hasOwnProperty('page')) params.page = payload.page;
                
                const query = toQueryString(params);

                const PATH = `${API_PATH}/items/${payload.articleId}/media${query}`;

                fetch(PATH, {
                    headers: headers
                }).then( (res) => {
                    if (res.ok) {
                        return res.json()
                    }
                }).then( (data) => {
                    const allPagesLoaded = data.hasOwnProperty('page') && data.hasOwnProperty('pages') && data.page >= data.pages;
                    commit('lightboxAssign', { allPagesLoaded: allPagesLoaded });
                    commit('removeActiveCall', ACTION_NAME, { root: true });
                    resolve(data._embedded.items);
                }).catch((err)=>{
                    console.warn(err);
                })
            });
        }
    }
}

export default lightbox;