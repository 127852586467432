<template>
    <ul>
        <li class="slide-navigation__item">
            <a :href="webroot + '/contact'" :tabindex="tabIndex">联系我们</a>
        </li>
        <li class="slide-navigation__item">
            <div class="slide-navigation__social-media-links">
                <div>关注我们</div>
                <div v-for="socialMediaLink in socialMediaLinks">
                    <a :href="socialMediaLink.path" target="_blank" :tabindex="tabIndex"><img class="slide-navigation__social-icon" :src="socialMediaLink.icon" alt="socialMediaLink.name"></a>
                </div>
            </div>
        </li>
    </ul>
</template>

<script>
    import { mapState, mapMutations } from 'vuex';
    import NAVIGATION from 'shared/constants/Navigation';
    import { WEBROOT } from 'shared/constants/Paths';
    import MODALS from 'shared/constants/Modals';
    import signinSignoutLink from 'shared/components/navigation/SigninSignoutLink.vue';
    import activeTabindex from 'shared/components/mixins/navigation/activeTabindex';

    export default {
        mixins: [activeTabindex],
        components: { signinSignoutLink },

        data: function(){
            return {
                affiliates: NAVIGATION.AFFILIATED_PUBLICATIONS,
                socialMediaLinks: NAVIGATION.SOCIAL_MEDIA_LINKS,
                webroot: WEBROOT,
            }
        },

        computed: {
            ...mapState({
                userIsLoggedIn: state => state.user.userIsLoggedIn,
                username: state => state.user.username
            }),
        },

        methods: {
            ...mapMutations(['openModal']),

            openRegisterForm: function(){
                this.openModal(MODALS.REGISTER_FORM);
            },

            openLoginForm: function(){
                this.openModal(MODALS.LOGIN_FORM);
            },
        },

        filters: {
            truncate: function(value, length){
                return value.substring(0, length)
            }
        },
    };
</script>