<template>
    <a class="related-article" :href="article.url">
        <div class="image-container image-container--2x1">
            <img v-if="article.media" class="related-article__image" :srcset="srcset(article.media)" :sizes="sizes" :src="`${IMAGE_BASE_PATH}${article.media.path}`" />
        </div>
        <h4 class="related-article__section" v-html="slug"></h4>
        <div class="related-article__info">
            <span class="related-article__title" v-html="article.titleFormatted"></span><br>
             <span v-html="article.writerFormatted"></span>
        </div>
    </a>
</template>
<script>
    import ITEM_TYPES from '../../../constants/ItemTypes';
    import srcsetFromMedia from 'shared/components/mixins/SrcsetFromMedia';
    import dateFormat from 'date-fns/format';
    import { IMAGE_BASE_PATH } from 'shared/constants/Paths';
    import { DATE_FORMAT_STRING } from 'shared/constants/Common';

    export default {
        name: 'related-article',
        props: ['article'],
        mixins: [srcsetFromMedia],
        data(){
            return {
                IMAGE_BASE_PATH: IMAGE_BASE_PATH,
            }
        },
        computed: {
            publishedDate: function(){
                return dateFormat(this.article.published, DATE_FORMAT_STRING);
            },
            slug: function(){
                if ( this.article.issueTitle ) { return '<strong>PRINT</strong> ' + this.article.issueTitle; }
                else { return `<strong>${this.article.siteSectionLabel}</strong> <span>${this.publishedDate}</span>`; }
            }
        }
    };
</script>
<style lang="scss">
    @import "scss/variables/breakpoints";
    @import "scss/variables/grid";
    @import "scss/variables/typography";

    .related-article{
        font-size: 1.0625rem;
        display: block;
        color: #000;
    }

    .related-article__title{
        font-weight: $demi;
    }
    
    .related-article__image{
        max-width: 100% !important;
    }
    
    .related-article__section{
        padding: 0;
        margin: 1rem 0px 0px 0px;
        text-align: left;
        font-family: $sans-base-font-family;
        text-transform: uppercase;
        font-weight: $book;
        line-height: 1.125em;
        margin-bottom: .25rem;

        span{
            text-transform: none;
        }
    }
    
    .related-article__info{
        text-align: left;
        font-family: $sans-base-font-family;
        line-height: 1.25em;
    }
    
    .related-article__section,
    .related-article__info{
        margin-left: $mobile-margins-left;
    }

    @media screen and (min-width: $breakpoint-medium){
        .related-article__section,
        .related-article__info{
            margin-left: 0;
        }
    }
</style>