<template>
	<div class="mobile-search">
		<search-toggle class="search__toggle search__toggle--mobile" :callback="open"></search-toggle>
		<overlay v-show="isActive">
			<div class="mobile-search__area">
				<form role="search">
					<label for="mobile_search_input" class="visually-hidden">search</label>
					<input id="mobile_search_input" ref="searchInput" class="search__input" v-model="inputValue" autocomplete="off" type="search" name="search" placeholder="search"></input>
					<button class="search__submit" type="submit" :formaction="searchPath">SUBMIT</button>
				</form>
			</div>
		</overlay>
	</div>
</template>

<script>
	import overlay from 'shared/components/container/Overlay.vue';
	import searchToggle from './SearchToggle.vue';
	import searchField from './mixins/SearchField';
	import MODALS from 'shared/constants/Modals';
	import NAVIGATION from 'shared/constants/Navigation';

	import { mapGetters, mapState, mapMutations, mapActions  } from 'vuex';

	export default {

		computed: {
			...mapState({ activeModal: state => state.activeModal }),
			...mapGetters({ isActive: 'mobileSearchIsActive' })
		},

		components: {
			overlay,
			searchToggle
		},

		methods: {
			open(){
				this.openModal(MODALS.MOBILE_SEARCH);
				this.$refs.searchInput.focus();
			}
		},

		mixins: [ searchField ]
	};
</script>

<style lang="scss">
	@import "scss/variables/breakpoints";
	.mobile-search{
		display: none;
	}

	@media screen and (max-width: $breakpoint-medium){
		.mobile-search{
			display: inline-block;
			flex-grow: 1;
			text-align: right;
		}

		.mobile-search__area{
			width: 90%;
			position: fixed;
			top: 20%;
			left: 50%;
			transform: translate(-50%, -50%);
			border-bottom: 1px solid #000;
			text-align: left;
		}

		.search__input{
			width: 100%;
			background: transparent;
		}

		.search__submit{
			visibility: hidden;
		}

		.search__suggestions{
			top: 1rem;
		}
	}
</style>