<template>
	<button href="#" id="menu-toggle" tabindex="0" class="nav-icon" v-bind:class="{ open: active, 'focus--mouse': wasClicked }" v-on:click.prevent="handleClick":blur="handleBlur">
  		<span></span>
  		<span></span>
  		<span></span>
  		<span></span>
      <span class="visually-hidden">Toggle Menu</span>
	</button>
</template>

<script>

export default {
	name: 'menu-toggle',
  data(){
    return {
      wasClicked: false
    }
  },
	props: ['active', 'callback'],
  methods: {
    handleClick: function(){
      this.callback();
      this.wasClicked = true;
    },
    handleBlur: function(){
      this.wasClicked = false;
    }
  }
};

</script>

<style scoped lang="scss">

@import "scss/variables/breakpoints";

button:focus{
	
}

.nav-icon {
  display: block;
	background: none !important;
	width: 22px;
	height: 22px;
	position: relative;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: .5s ease-in-out;
	-moz-transition: .5s ease-in-out;
	-o-transition: .5s ease-in-out;
	transition: .5s ease-in-out;
	cursor: pointer;
}

.nav-icon span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #000;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

.nav-icon span:nth-child(1) {
  top: 0px;
}

.nav-icon span:nth-child(2),.nav-icon span:nth-child(3) {
  top: 9px;
}

.nav-icon span:nth-child(4) {
  top: 18px;
}

.nav-icon.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

.nav-icon.open span{
  background: #fff;
}

.nav-icon.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.nav-icon.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.nav-icon.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}

@media screen and (min-width: $breakpoint-medium){
  .nav-icon.open span{
    background: #000;
  }
}

</style>