<template>
    <div class="related-articles__slider--mobile lory__slider related-articles__js_simple_dots--mobile related-articles__js_slider--mobile mobile-full-width">
        <div class="related-articles__frame--mobile lory__frame related-articles__js_frame--mobile">
            <ul class="related-articles__slides--mobile lory__slides related-articles__js_slides--mobile">
                <li class="related-articles__slide--mobile lory__slide related-articles__js_slide--mobile" v-for="article in articles">
                    <related-article :article="article" preferred-img-crop="landscape"></related-article>
                </li>
            </ul>
        </div>
        <ul v-show="articles.length > 1" class="related-articles__js_dots--mobile dots">
            <li v-for="(slide, index) in articles" :class="{active: activeDot(index)}"></li>      
        </ul>
    </div>
</template>

<script>
    import RelatedArticle from './RelatedArticle.vue';
    import { lory } from 'lory.js';

    export default {
        name: 'mobile-carousel',
        props: ['articles'],
        data(){
            return {
                slideIndex: 0,
            }
        },

        methods: {
            activeDot(index){
                return index === this.slideIndex;
            }
        },

        mounted: function(){
            var simple_dots = this.$el;

            this.$el.addEventListener('after.lory.slide', (e) => {
                this.slideIndex = e.detail.currentSlide;
            });
            
            this.$nextTick(function(){
                var dot_navigation_slider = lory(this.$el, {
                    classNameFrame: 'related-articles__js_frame--mobile',
                    classNameSlideContainer: 'related-articles__js_slides--mobile',
                    slidesToScroll: 1,
                    rewindOnResize: false
                });
            });
        },
        components: {
            'related-article': RelatedArticle
        }
    };
</script>

<style lang="scss">
    @import "scss/variables/typography";
    
    .related-articles__slides--mobile {
        margin: 1rem 0 1rem 0;
    }
    
    .related-articles__slide--mobile {
        text-align: center;
        width: 100vw;
    }
    
    .related-articles__slider--mobile .prev, .related-articles__slider--mobile .next{
        display: none;
    }
    
    .related-articles__js_dots--mobile{
        text-align: center;
    }
    
    .related-articles__js_dots--mobile li{
        display: inline-block;
        width: 8px;
        height: 8px;
        background: #aaa;
        margin-right: 15px;
        border-radius: 8px;
    }
    
    .related-articles__js_dots--mobile li.active{
        background: #000;
    }
</style>