/** 
* This function takes an HTML node and re-injects any scripts within it so that they are executable 
* This work-around exists so that scripts added as innerHTML will be loaded and executed.
*
* @param {Element} el
*/
export default function($el){
    const scripts = $el.getElementsByTagName('script');
    for(let i = 0; i < scripts.length; i++){
        let node = scripts[i];
        let parent = node.parentElement;
        let el = document.createElement('script');
        el.src = node.src;
        parent.insertBefore(el, node);
        parent.removeChild(node);
    }
}