import { SITE_URL, WEBROOT, SITE_PATH, API_PATH, ADMIN_API_PATH } from './Paths';

const NAVIGATION = 
{
    SITE_URL: SITE_URL,
    WEBROOT: WEBROOT,
    SITE_PATH: SITE_PATH,
    PAGES: [
        {
            name: 'artguide',
            path: 'https://www.artforum.com/artguide/place/beijing',
            top: true,
        },
        {
            name: '新闻',
            path: '/news',
            top: true,
        },
        {
            name: '展评',
            path: '/picks',
            top: true,
        },
        {
            name: '杂志',
            path: '',
            top: true,
            subpages: [
                {
                    name: '本期杂志',
                    path: '/print'
                },
                {
                    name: '往期内容',
                    path: '/print/archive'
                }
            ]
        },
        {
            name: '专栏',
            path: '/columns',
            top: true,
            subpages: [
                {
                    name: '所见所闻',
                    path: '/diary'
                },
                {
                    name: '采访',
                    path: '/interviews'
                },
                {
                    name: '观点',
                    path: '/slant'
                },
                {
                    name: '影像',
                    path: '/film'
                },
                {
                    name: '书评',
                    path: '/books'
                },
                {
                    name: '现场',
                    path: '/onsite'
                },
            ]
        },
        {
            name: '视频',
            path: '/video',
            top: true,
        },
        {
            name: 'ENGLISH',
            path: 'https://www.artforum.com/',
            top: true,
        },
        {
            name: 'ART & EDUCATION',
            path: 'https://www.artandeducation.net/',
            top: true,
        },
        {
            name: '广告',
            path: '/advertise',
            top: false,
        },
        {
            name: '订阅',
            path: 'https://www.artforum.com/subscribe',
            top: true,
        },
        {
            name: '往期内容',
            path: '/print/archive',
            top: false,
        }
    ],
    SOCIAL_MEDIA_LINKS: [
        {
            name: 'weibo',
            icon: '/static-assets/weibo-logo_white.svg',
            path: 'http://www.weibo.com/u/1750011241'
        },
        {
            name: 'wechat',
            icon: '/static-assets/wechat_white.svg',
            path: `${SITE_PATH}/wechat-link`,
        },
        {
            name: 'facebook',
            icon: '/static-assets/social-icons/facebook.svg',
            path: 'https://www.facebook.com/artforumcn',
        },
    ],
}

export default NAVIGATION;
