import { API_PATH }from 'shared/constants/Paths';

const news = {
	namespaced: true,

	state: {
		frontPageNews: []
	},

	mutations: {
		setFrontpageNews(state, news){
			state.frontPageNews = news;
		}
	},

	actions: {
		getFrontPageNews({ commit }){
			const NEWS_URL = API_PATH + '/items?category=news&pageSize=19';

			const headers = new Headers({
                'Accept': 'application/json',
                //'Authorization': 'Bearer ' + auth.getToken()
            });

            fetch(NEWS_URL, {
            	headers: headers,
            	credentials: 'include'
            }).then((res) => {
            	if(!res.ok){
            		throw new Error('could not fetch news');
            	}
            	return res.json();
            }).then((data) => {
            	commit('setFrontpageNews', data._embedded.items);
            	return true;
            })
		}
	}
}

export default news;