<template>
    <form class="form" v-if="!userIsLoggedIn">
        <slot name="before">
            <h1 class="form__header">SIGN IN</h1>
            <p class="form__text" v-html="prompt" />
        </slot>

        <p v-if="this.loginMessage.length" role="alert" class="form__message--error">{{loginMessage}}</p>

        <label for="login_form_email" class="visually-hidden">email</label>
        <input id="login_form_email" class="form__text-input" type="email" v-model="credentials.username" placeholder="username or email" name="email">
        <label for="login_form_password" class="visually-hidden">password</label>
        <input id="login_form_password" class="form__text-input" type="password" v-model="credentials.password" placeholder="password" name="password">

        <p class="form__fine-print"><a :href="recoverLink">Forgot your password?</a></p>

        <submit-button class="form__submit" button-text="Submit" :callback="submit" associated-action="userLogin"></submit-button>

        <slot name="after">
            <p class="form__text">
                Not registered for artforum.com? <a href="#" v-on:click.prevent="openRegisterForm">Register here.</a>
            </p>
            <p class="form__fine-print">
                提交信息表示您同意<a href="https://pmc.com/privacy-policy/" title="Privacy Policy" target="_Blank">隐私政策</a>和<a href="https://pmc.com/terms-of-use/" title="Terms of use" target="_Blank">使用条款</a>（包含适用于强制仲裁和集体诉讼豁免条款）。我们使用供应商来收集和储存信息。
            </p>
        </slot>
    </form>
</template>
<script>
    import auth from 'lib/auth';
    import { mapState, mapMutations, mapActions } from 'vuex';
    import submitButton from 'shared/components/forms/SubmitButton.vue';
    import MODALS from 'shared/constants/Modals.js';
    import { SITE_PATH, WEBROOT } from 'shared/constants/Paths';

    export default {
        name:'af-login',
        data(){
            return {
                credentials: {
                    username: '',
                    password: '',
                },
                privacyLink: `${SITE_PATH}/privacy`,
                termsLink: `${SITE_PATH}/terms`
            }
        },
        computed: {
            ...mapState({
                userIsLoggedIn: state => state.user.userIsLoggedIn,
                loginMessage:   state => state.user.loginMessage,
                activeModal:    state => state.activeModal,
                prompt:         state => state.login.prompt
            }),

            recoverLink: () => `${WEBROOT}/recover`
        },
        methods: {
            ...mapMutations(['openModal', 'closeModal']),
            ...mapMutations('login', ['resetLoginPrompt']),
            ...mapActions('user', [
                'userLogin',
                'userLogout'
            ]),
            submit(){
                const credentials = {
                    _username: this.credentials.username,
                    _password: this.credentials.password
                };
                this.userLogin(credentials);

            },
            logoutUser: function(){
                this.userLogout();
            },
            openRegisterForm(){
                this.openModal(MODALS.REGISTER_FORM);
            },
            resetFields(){
                this.credentials.username = '';
                this.credentials.password = '';
            },
        },
        watch: {
            userIsLoggedIn: function(theyAre){
                if ( theyAre  && this.activeModal === MODALS.LOGIN_FORM ) {
                    window.setTimeout(()=>{this.closeModal()}, 200);
                }
            },
            activeModal: function(modal) {
              if (modal !== MODALS.LOGIN_FORM) {
                // reset login prompt on every modal close
                this.resetLoginPrompt();
                this.resetFields();
              }
            }
        },
        components:{
            'submit-button': submitButton
        }
    };
</script>
