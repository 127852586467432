<template>
	<transition name="fade">
		<div :class="containerClasses">
			<div class="overlay__wrapper">
				<div class="overlay__header">
					<a :class="collapseClasses" v-on:click.prevent="close"></a>
				</div>
				<div class="overlay__content" :class="contentClass">
					<slot></slot>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
	import { mapMutations } from 'vuex';
	export default {
		name: 'af-overlay',
		props: {
			'contentClass': {
				type: String,
				required: false,
			},
			'beforeClose': {
				type: Function,
				required: false
			},
			'dark': {
				type: Boolean,
				required: false,
				default: false,
			}
		},
		
		computed: {
			containerClasses: function(){
				return {
					'overlay': true,
					'overlay--dark': this.dark,
				};
			},
			collapseClasses: function(){
				return {
					'overlay__collapse': true,
					'overlay__collapse--dark': this.dark,
				};
			}
		},

		methods: {
			...mapMutations(['closeModal']),

			close: function(){
				if (typeof this.beforeClose === 'function') {
					this.beforeClose();
				}
				this.closeModal();
			}
		}
	};
</script>

<style lang="scss">
	@import "scss/transitions/fade";
</style>