<template>
    <article class="hp-bloglist__article">
        <a v-if="index === 0" :href="article.url">
            <div class="image-container image-container--2x1 image-container--1x1-mobile mobile-full-width">
                <picture>
                    <source :media="mediaQueryMobile" :srcset="srcset(article.media, 'square')" :sizes="sizes">
                    <source :media="mediaQueryDesktop" :srcset="srcset(article.media, 'landscape')" :sizes="sizes">
                    <img class="hp-bloglist__image" :srcset="srcset(article.media, 'landscape')" :sizes="sizes" :src="`${IMAGE_BASE_PATH}${article.media.pathLarge}`" :alt="article.titleFormatted | stripTags">
                </picture>
            </div>
        </a>
        <div>
            <h3 class="hp-bloglist__title"><a :href="article.url" v-html="article.titleFormatted"></a></h3>
            <p class="hp-bloglist__teaser"><a :href="article.url" v-html="subtitle"></a></p>
        </div>
    </article>
</template>

<script>
    import srcsetFromMedia from 'shared/components/mixins/SrcsetFromMedia';
    import stripTags from 'shared/components/mixins/StripTags';
    import { BREAKPOINT_MEDIUM } from 'shared/constants/Common';

    export default {
        props: {
            article: {
                required: true,
                type: Object
            },
            index: {
                required: true,
                type: Number
            },
        },
        data(){
            return {
                mediaQueryMobile: `(max-width: ${BREAKPOINT_MEDIUM}px)`,
                mediaQueryDesktop: `(min-width: ${BREAKPOINT_MEDIUM}px)`,
            }
        },

        computed: {
            subtitle: function(){
                const INTERVIEWS_TYPE = 8;
                const prepend = this.article.type === INTERVIEWS_TYPE ? '采访／' : '文／'; // interviews receive special treatment 
                return this.article.subtitleFormatted ? this.article.subtitleFormatted : `${prepend}${this.article.writerFormatted}`;
            }
        },

        mixins: [srcsetFromMedia, stripTags],
    };
</script>