<template>
	<li class="nav__item" :class="{ bigbold: isLowercase, 'nav__item--current': isCurrent, 'nav__item--clickable': url }" key="page.name" v-on:mouseover="showSubnav" v-on:mouseout="hideSubnav">
		<a v-if="isSubscribe" class="nav-link" href="https://www.ezsubscription.com/art/subscribe.asp" target="_blank" rel="noopener" tabindex="-1">{{ page.name }}</a>
		<a v-else class="nav-link" :href="url" v-on:focus="showSubnav":blur="hideSubnav" v-on:click="preventIfEmpty" :target="target" :rel="rel" tabindex="-1">{{page.name}}</a>
		<ul class="subnav" v-show="renderDeep">
			<nav-item-top v-for="subpage in subpagesFiltered" :key="subpage.name" :page="subpage" :deep="false" class="subnav__item">
			</nav-item-top>
		</ul>
	</li>
</template>

<script>
import navItem from './mixins/NavItem';
import { mapState } from 'vuex';
import NAVIGATION from 'shared/constants/Navigation';

import _map from 'lodash/map';
import _filter from 'lodash/find';

export default {
	name: 'nav-item-top',

	computed: {
		// remove this hack once circulation is ready to bring all subs in house
		isSubscribe: function(){
			return this.page.name.toLowerCase() === 'subscribe';		
		}
	},
	
	methods: {
		showSubnav: function(){
			this.isExpanded = true;
		},
		hideSubnav: function(){
			this.isExpanded = false;
		}
	},

	mixins: [navItem]
};
</script>

<style scoped lang="scss">
@import "scss/variables/issue";

.nav__item{
	display: block;
	position: relative;
	overflow: visible;
}

.nav__item:hover{
	cursor: pointer;
}

.subnav__item a:hover, .nav__item--clickable a:hover{
	color: $issue-color;
}

.nav__item--current > a{
	color: $issue-color;
}

.toggle-link:hover{
	cursor: pointer;
}

.subnav{
	position: absolute;
	left: -.5em;
	padding: .25em .25em .5em .5em;
	background: #fff;
	padding-top: 1.5rem;
	margin-left: 0px;
	text-align: left;
	width: 175px;
}

.subnav__item{
	display: block;
	overflow-x: visible;
	margin-bottom: .25em;
}

.bigbold{
	line-height: .625em;
}
</style>