import { SITE_PATH } from 'shared/constants/Paths';
import { mapState, mapGetters } from 'vuex';
export default {
	props: {
		page: Object,
		deep: Boolean,
		expanded: Boolean
	},
	data(){
		return {
			sitePath: SITE_PATH,
			isExpanded: this.expanded
		}
	},
	computed: {
		...mapState({
			category: (state) => state.navigation.category
		}),

		...mapGetters('navigation', ['parentCategory']),

		isCurrent: function(){
			return this.category 
				&& ( this.category.toLowerCase() === this.page.name.toLowerCase()
					|| this.parentCategory.toLowerCase() === this.page.name.toLowerCase()
					|| document.location.pathname === this.page.path
				);
		},

		isOutboundLink: function(){
			return this.page.path.indexOf('//') > -1;
		},

		subpagesFiltered: function(){
			return this.page.subpages ? this.page.subpages.filter((subpage) => !subpage.hasOwnProperty('hidden') || !subpage.hidden) : [];
		},

		renderDeep: function(){
			return this.deep && this.subpagesFiltered.length > 0 && this.isExpanded;
		},

		isExpandable: function(){
			return this.deep && this.subpagesFiltered.length > 0;
		},

		isLowercase(){
			let nonAscii = /[^\x00-\x7F]+/.test(this.page.name);
			return ( ( this.page.name.toLowerCase() === this.page.name ) && !nonAscii);
		},

		url(){
			if ( this.page.path === '') return false;
			return this.isOutboundLink ? this.page.path : this.sitePath + this.page.path;
		},

		target: function(){
			return this.isOutboundLink ? '_blank' : '_self';
		},

		rel: function(){
			return this.isOutboundLink ? 'noopener' : '';
		}
	},
	methods: {
		toggle: function(){
			this.isExpanded = !this.isExpanded
		},

		preventIfEmpty: function(e){
			if (this.page.path === '' && e){
				e.preventDefault();
				return false;
			}
		}
	}
}