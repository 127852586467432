import { BREAKPOINT_MEDIUM, BREAKPOINT_SMALL } from 'shared/constants/Common';

const viewport = {
	namespaced: true,

	state: {
		scrollY: 0,
        lastLoggedPosition: 0,
		scrolledDown: false,
		windowHeight: null,
        windowWidth: null,
        headerHeightMinimized: 79, //px
	},

	getters: {
		scrollBottom: (state) => state.scrollY + state.windowHeight,
        isMobile: (state) => typeof state.windowWidth === 'number' && state.windowWidth <= BREAKPOINT_MEDIUM,
        isSmall:  (state) => typeof state.windowWidth === 'number' && state.windowWidth <= BREAKPOINT_SMALL,
        pageHeight: (state) => typeof state.windowHeight === 'number' ? state.windowHeight - state.headerHeightMinimized : null,
	},

	mutations: {
		receiveScrollY(state, position){
            const THRESHOLD = 32;
            const delta =  position - state.lastLoggedPosition;
            const thresholdPassed = Math.abs(delta) > THRESHOLD;
            
            state.scrollY = position;

            if (thresholdPassed) {
                state.lastLoggedPosition = position;
                if( delta > 0 ){
                    state.scrolledDown = true;
                }
                else{
                    state.scrolledDown = false;
                }
            }
        },

        setWindowHeight(state, windowHeight){
            state.windowHeight = windowHeight;
        },

        setWindowWidth(state, windowWidth){
            state.windowWidth = windowWidth;
        }
	}
};

export default viewport;