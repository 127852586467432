import "core-js/features/object";
import "core-js/features/array";
import "core-js/features/promise";
import "core-js/features/string/split";
import "core-js/features/date";

import Vue from 'vue';
import styles from '../../scss/main.scss';

import App from                      './components/App.vue';
import SlideNavigation from          'shared/components/navigation/SlideNavigation.vue';
import Header from                   './components/Header.vue';
import MoreImagesLink from           './components/article/MoreImagesLink.vue';
import Lightbox from                 './components/article/Lightbox.vue';
import MoreArticles from             './components/listing/MoreArticles.vue';
import LoginModal from               './components/forms/LoginModal.vue';
import LoginForm from                './components/forms/LoginForm.vue';
import SigninSignoutLink from        'shared/components/navigation/SigninSignoutLink.vue';
import RelatedArticles from          './components/article/RelatedArticles.vue';
// import RegisterForm from             './components/forms/RegisterForm.vue';
import RegisterFooter from           './components/forms/RegisterFooter.vue';
import PasswordRecovery from         './components/forms/PasswordRecovery.vue';
// import Recaptcha from                './components/forms/ReCaptcha.vue';
import StickyAnchorLinks from        './components/navigation/StickyAnchorLinks.vue';
import LeaderboardAd from            './components/ads/Leaderboard.vue';
import GalleryAdsDesktop from        './components/ads/GalleriesDesktop.vue';
import GalleryAdsMobile from         './components/ads/GalleriesMobile.vue';
import BannerAd from                 './components/ads/BannerAd.vue';
import ShareToggle from              './components/article/ShareToggle.vue';
import SearchSort from               'shared/components/search/SearchSort.vue';

import HomepageNews from             './components/homepage/News.vue';
import HomepageBlogList from         './components/homepage/BlogList.vue';
import HomepageBookforum from        './components/homepage/Bookforum.vue';
// import HomepageSponsoredVideos from  './components/homepage/SponsoredVideos.vue';
// import ExhibitionHighlights from     './components/homepage/ExhibitionHighlights.vue';

import ModalLink from                './components/links/ModalLink.vue';
import LazyLoadMore from             './components/listing/LazyLoadMore.vue';
import Toggle from                   'shared/components/container/Toggle.vue';
import DotCarousel from              './components/carousel/DotCarousel.vue';
import DotDotDot from                './components/animations/DotDotDot.vue';
import PopupAd from                  './components/ads/PopupAd.vue';
// import VideoSliderJS from            './components/video/SliderJS.vue';
// import Instagram from                './components/instagram/Instagram.vue';

// import DatePicker from               'shared/components/forms/DatePicker.vue';

import store from './store/index.js';
import trackFocus from 'lib/utils/TrackFocus';

/* App container */
Vue.component('af-app', App);


/* Navigation */
Vue.component('af-sticky-anchor-links', StickyAnchorLinks);
Vue.component('af-slide-navigation', SlideNavigation);
Vue.component('af-header', Header);

/* Lightbox and images */
Vue.component('af-more-images-link', MoreImagesLink);
Vue.component('af-lightbox', Lightbox);

/* Article listings */
// Vue.component('af-load-more-button', LoadMoreButton);
Vue.component('af-more-articles', MoreArticles);
// Vue.component('af-more-videos', MoreVideos);
Vue.component('af-lazy-load-more', LazyLoadMore);

/* Forms */
Vue.component('af-login-modal', LoginModal);
Vue.component('af-login-form', LoginForm);
Vue.component('af-signin-signout-link', SigninSignoutLink);
Vue.component('af-related-articles', RelatedArticles);
// Vue.component('af-register-form', RegisterForm);
Vue.component('af-register-form-footer', RegisterFooter);
Vue.component('af-password-recovery-form', PasswordRecovery);
// Vue.component('af-recaptcha', Recaptcha);
// Vue.component('af-date-picker', () => import( webpackChunkName: "DatePicker"  'shared/components/forms/DatePicker.vue'));

/* Ads */
Vue.component('af-leaderboard-ad', LeaderboardAd);
Vue.component('af-gallery-ads-desktop', GalleryAdsDesktop);
Vue.component('af-gallery-ads-mobile', GalleryAdsMobile);
Vue.component('af-banner-ad', BannerAd);
Vue.component('af-popup-ad', PopupAd);
// Vue.component('af-instagram', Instagram);

Vue.component('af-share-toggle', ShareToggle);
Vue.component('af-search-sort', SearchSort);

/* Homepage */
Vue.component('af-homepage-news', HomepageNews);
Vue.component('af-homepage-bloglist', HomepageBlogList);
Vue.component('af-homepage-bookforum', HomepageBookforum);
// Vue.component('af-exhibition-highlights', ExhibitionHighlights);
// Vue.component('af-homepage-sponsored-videos', HomepageSponsoredVideos);

/* Animations */
Vue.component('af-dot-dot-dot', DotDotDot);

/* Misc */
Vue.component('af-toggle', Toggle);
Vue.component('af-dot-carousel', DotCarousel);
// Vue.component('af-video-slider-js', VideoSliderJS);
Vue.component('af-modal-link', ModalLink);

Vue.config.devtools = true;

function populateLightboxImages(articleId){
    store.dispatch('getArticleImages', articleId);
}

function recaptchaOnloadCallback(){
    store.commit('reCaptcha/recaptchaLoaded');
}

window.populateLightboxImages = populateLightboxImages;
window.recaptchaOnloadCallback = recaptchaOnloadCallback;

(function(){

    const app = new Vue({
        el: '#container',
        store: store,
    });

    // for(let i = 0; i < vueElements.length; i++){

    //     let els = document.getElementsByTagName(vueElements[i]);
    //     /** 
    //      * using a while loop here because transforming a vue element
    //      * into a Vue component removes it from the HTMLCollection in real time,
    //      * so looping through the elements reduces els.length to 0
    //      */
    //     while(els.length > 0){
    //         let el = els.item(0);
    //         try{
    //             const app = new Vue({
    //                 el: el,
    //                 store: store,
    //             });
    //         }
    //         catch(e){
    //             console.warn(e);
    //             el.parentNode.removeChild(el); // avoid bad infinity
    //         }
    //     }
    // }

    // this disables outlining for clicked focusable elements
    // while leaving focus styles intact for tab navigation
    document.body.classList.add('focus--mouse');
    trackFocus(document.body);

    // load recaptcha - necessary to load via js so the callback is available on load
    var recaptchaScript = document.createElement('script');
    recaptchaScript.src = 'https://www.google.com/recaptcha/api.js?onload=recaptchaOnloadCallback&render=explicit';
    document.body.appendChild(recaptchaScript);
})();
