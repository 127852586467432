import { API_PATH }from 'shared/constants/Paths';
import toQueryString from 'lib/utils/ToQueryString';
import { ARTICLES_PER_PAGE } from 'shared/constants/Common';

const articles = {
    namespaced: true, 

    state: {
        dynamicallyLoadedArticles: [],
        paged: 1,
        pageSize: ARTICLES_PER_PAGE,
        allPagesLoaded: false,
        sectionFeatured: {
            diary: [],
            interviews: [],
            slant: [],
            film: [],
            performance: [],
            passages: [],
            architecture: [],
            music: [],
            books: [],
            topten: [],
            video: [],
        },
    },

    getters: {
        dynamicallyLoadedArticlesCount: (state) => state.dynamicallyLoadedArticles.length,
        lastPageLoaded: (state) => ( typeof pagesTotal === 'number' ) && ( state.paged >= state.pagesTotal )
    },

    mutations: {
        articlesAssign(state, data){
            Object.assign(state, data);
        },

        addMoreArticles(state, articles){
            state.dynamicallyLoadedArticles.push.apply(state.dynamicallyLoadedArticles, articles);
        },

        incrementPaged(state, options){
            state.paged++;
        },

        setSectionFeatured(state, options){
            const { category, articles } = options;
            state.sectionFeatured[category] = articles;
        }
    },

    actions: {
        loadMoreArticles({ commit }, options){
            commit('addActiveCall', 'loadMoreArticles', { root: true });
            /* 
            *  options include the category and page number, which are passed as url params, 
            *  and an (optional) offset which is used to slice items off the front 
            */
            const { 
                offset,
                storePaged,
                itemType, 
                ...urlParams } = options;

            const apiRoute = itemType ? `/${itemType}` : '/items'; 

            const _url = urlParams.category ? `${API_PATH}${apiRoute}${toQueryString(urlParams)}` : `${API_PATH}${apiRoute}/columns${toQueryString(urlParams)}`;

            const headers = new Headers({
                'Accept': 'application/json'
            });
            
            fetch( _url, {
                headers: headers
            }).then( (res) => {
                if(res.ok){
                    return res.json();
                }
                else{
                    const status = res.status;
                    throw { status: status, message:'There was an error loading articles from the API.' };
                }
            }).then( (data) => {
                const items = offset > 0 ? data._embedded.items.slice(offset, data._embedded.items.length) : data._embedded.items;
                const allPagesLoaded = data.page >= data.pages;

                if(!allPagesLoaded){
                    commit('incrementPaged', {storePaged: storePaged});
                }

                commit('articlesAssign', { allPagesLoaded: allPagesLoaded });
                commit('addMoreArticles', items);
                commit('removeActiveCall', 'loadMoreArticles', { root: true });    
            }).catch((error) => {
                commit('removeActiveCall', 'loadMoreArticles', { root: true });
                if( error.status === 404 ){
                    commit('articlesAssign', { allPagesLoaded: true });
                }
            });
        },

        loadMoreVideos({ commit }, options){
            commit('addActiveCall', 'loadMoreVideos', { root: true });

            const {
                offset, 
                storePaged, 
                ...urlParams
            } = options;

            const _url = `${API_PATH}/items${toQueryString(urlParams)}`;

            const headers = new Headers({
                'Accept': 'application/json'
            });

            fetch(_url, {
                headers: headers
            })
            .then((res) => {
                if(!res.ok){
                    const status = res.status;
                    throw({status: status, message: 'There was an error loading videos from the API'});
                }
                return res.json();
            })
            .then((data) => {
                const items = offset > 0 ? data._embedded.items.slice(offset, data._embedded.items.length) : data._embedded.items;
                const allPagesLoaded = data.page >= data.pages;
                if(!allPagesLoaded){
                    commit('incrementPaged', {storePaged: storePaged});
                }

                commit('articlesAssign', {allPagesLoaded: allPagesLoaded});
                commit('addMoreArticles', items);
                commit('removeActiveCall', 'loadMoreVideos', { root: true });
            })
            .catch((error) => {
                commit('removeActiveCall', 'loadMoreVideos', { root: true });
                if( error.status === 404 ){
                    commit('articlesAssign', { allPagesLoaded: true });
                }
            });
        },

        getSectionFeaturedArticles({ commit }, options){
            commit('addActiveCall', 'getSectionFeaturedArticles', { root: true });

            const {category, pageSize} = options;

            const _url = `${API_PATH}/items/homepage?flag_name=section_feature&category=${category}&page_size=${pageSize}`;

            const headers = new Headers({
                'Accept': 'application/json'
            });

            fetch(_url, {
                headers: headers
            }).then((res) => {
                if(res.ok){
                    return res.json();
                }
                else{
                    const status = res.status;
                    throw { status: status, message: 'There was an error loading featured articles from the API' };
                }
            }).then((data) =>{
                if (typeof data._embedded !== 'undefined' && typeof data._embedded.items !== 'undefined'){
                    var items = data._embedded.items;
                }
                else {
                    var items = [];
                }
                commit('setSectionFeatured', { category: category, articles: items });
                commit('removeActiveCall', 'getSectionFeaturedArticles', { root: true });
            }).catch((err) => {
                console.warn(err.status, err.message);
                commit('removeActiveCall', 'getSectionFeaturedArticles', { root: true });
            });
        },
    }
}

export default articles;