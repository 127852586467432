<template>
    <af-overlay v-if="loginFormIsVisible" content-class="register__content">
        <af-login-form></af-login-form>
    </af-overlay>
</template>
<script>
    import { mapState, mapGetters, mapMutations } from 'vuex';
    import overlay from 'shared/components/container/Overlay.vue';
    import loginForm from './LoginForm.vue';
    import MODALS from 'shared/constants/Modals.js';
    
    export default {
        name:'af-login',

        computed: {
            ...mapState({
                userIsLoggedIn: state => state.user.userIsLoggedIn,
                activeModal:    state => state.activeModal,
            }),
            ...mapGetters(['loginFormIsVisible']),
        },

        methods: {
            ...mapMutations('login', ['resetLoginPrompt', 'resetFields']),
        },

        watch: {
            userIsLoggedIn: function(theyAre){
                if ( theyAre  && this.activeModal === MODALS.LOGIN_FORM ) {
                    window.setTimeout(()=>{this.closeModal()}, 200);
                }
            },
            activeModal: function(modal) {
              if (modal !== MODALS.LOGIN_FORM) {
                this.resetLoginPrompt()
              }
            },
        },

        components:{
            'af-overlay': overlay,
            'af-login-form': loginForm
        }
    };
</script>
