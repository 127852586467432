<template>
    <li>
        <article class="article-list__article">
            <div v-if="showChannel" class="slug--single-article">
                <a :href="channelLink">{{article.siteSectionLabel}}</a>
            </div>
            <figure v-if="article.media" class="primary-image--no-crop">
                    <a :href="article.url">
                        <img :src="`${IMAGE_BASE_PATH}${article.media.path}`" :srcset="srcset(article.media)" :sizes="sizes" :alt="article.titleFormatted | stripTags">
                    </a>                            
            </figure>
            <h1 class="article-list__h1">
                <a :href="article.url" v-html="article.titleFormatted"></a>
            </h1>
            <div class="article-list__article-meta">
                <span class="article-list__byline" v-html="article.writerFormatted"></span> • 
                <span class="article-list__publish-date">
                        <a v-if="article.issueTitle" :href="article.issuePath">
                            <strong>PRINT</strong> {{ article.issueTitle }}
                        </a>
                        <span v-else>{{ article.published | formatDate("YYYY.MM.DD") }}</span>
                </span>
            </div>
            <section class="article-list__content" v-html="article.bodyExcerpt"></section>
            <div class="article-list__read-more">
                <a class="article-list__read-more-link" :href="article.url">阅读全文</a>
            </div>
        </article>
    </li>

</template>

<script>
  import article from './mixins/article';
  
	export default {
        mixins: [ article ]
	};
</script>