const reCaptcha = {
	namespaced: true,

	state: {
		recaptchaIsLoaded: false,
	},
	
	mutations: {
		recaptchaLoaded(state){
            state.recaptchaIsLoaded = true;
        },
	}
}

export default reCaptcha;