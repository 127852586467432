import { mapState } from 'vuex';

export default {
	props: {
		associatedAction: {
			type: String,
			required: true
		},

		invertColor: {
			type: Boolean,
			required: false,
			default: false
		}
	},

	computed: {
		...mapState(['activeCalls']),

		show: function() {
			return this.activeCalls.indexOf(this.associatedAction) > -1;
		}
	}
}