<template>
	<div class="lory__wrapper mobile-full-width">
	<section class="hp-news__slider hp-news__js_slider lory__slider">
		<div class="hp-news__frame hp-news__js_frame lory__frame">
			<ul class="hp-news__slides hp-news__js_slides lory__slides">
				<li class="hp-news__slide hp-news__js_slide lory__slide" v-for="(newsItem, index) in newsItems">
					<news-item :article="newsItem" 
						:desktop-img-size="263" 
						preferred-img-crop="square" 
						:index="index"
						:slides-per-screen="slidesPerScreen" 
						:slide-index="slideIndex"></news-item>
				</li>
				<li class="hp-news__slide hp-news__js_slide lory__slide">
					<a class="hp-news__more-news-link" :href="newsLink">
						<span class="hp-news__more-news-link__text">更多新闻</span>
					</a>
				</li>
			</ul>
		</div>
		<span class="js_prev prev">
      		<img class="slider__arrow" src="~img/arrow_black_left.svg" alt="previous">
  		</span>
  		<span class="js_next next">
  		    <img class="slider__arrow" src="~img/arrow_black_right.svg" alt="next">
  		</span>
	</section>
	</div>
</template>

<script>
	import newsItem from './NewsItem.vue';
	import { lory } from 'lory.js';
	import { mapGetters } from 'vuex';

	export default {
		props: {
			newsItems: Array,
			newsLink: String
		},
		data: function(){
			return{
				slideIndex: 0
			}
		},
		computed: {
			...mapGetters('viewport', ['isSmall']),
			slidesPerScreen: function(){
				return this.isSmall ? 1 : 4;
			}
		},
		methods: {

			onLoryInit: function(){
				this.$el.getElementsByClassName('hp-news__js_slide')[0].style.left = '-5vw'
			},

			setCurrentSlide(e){
				this.slideIndex = e.detail.currentSlide;
			},
	
			adjustFirstSlide: function(e){
				if( e.detail !== null && e.detail.currentSlide > 1 ){
					return;
				}
				const offset = e.detail === null || e.detail.currentSlide === 0 ? '-5vw' : '0vw'; 
				this.$el.getElementsByClassName('hp-news__js_slide')[0].style.left = offset;
			},

			initLory: function(isSmall){
				document.addEventListener('after.lory.slide', this.setCurrentSlide);

				if (isSmall) {
					document.addEventListener('after.lory.init', this.adjustFirstSlide);
					document.addEventListener('after.lory.slide', this.adjustFirstSlide);
				}
				else{
					document.removeEventListener('after.lory.init', this.adjustFirstSlide);
					document.removeEventListener('after.lory.slide', this.adjustFirstSlide);
					this.$el.getElementsByClassName('hp-news__js_slide')[0].style.left = '0vw';
				}

				const slidesToScroll = this.slidesPerScreen;

				this.$nextTick(function(){
					lory(this.$el, {
						classNameFrame: 'hp-news__js_frame',
						classNameSlideContainer: 'hp-news__js_slides',
						slidesToScroll: slidesToScroll,
						rewindOnResize: false
					});
				});
			}
		},
		watch: {
			isSmall(bool){
				this.initLory(bool);
			}
		},
		mounted: function(){
			this.$nextTick(function(){
				this.initLory(this.isSmall);	
			});
		},
		components: {newsItem},
	}
</script>

<style lang="scss">
	
	@import "scss/variables/breakpoints";

	.hp-news__slider .next, .hp-news__slider .prev {
		top: 32%;
	}

	.hp-news__slides{
		display: block;
	}

	.hp-news__slide{
		width: calc(25% - .75rem);
		padding-right: 1rem;
	}

	

	.hp-news__more-news-link{
		position: relative;
		display: block;
		height: 0px;
		padding: 50%;
		background: #fafafa;
	}

	.hp-news__more-news-link__text{
		position: absolute;
    	top: 50%;
    	left: 50%;
    	transform: translate(-50%, -50%);
    	font-weight: 100;
    	text-transform: uppercase;
    	font-size: 1.25rem;
	}

	@media screen and (min-width: $breakpoint-small){
		.hp-news__slide:nth-of-type(4n){
			padding-right: 0px;
		}
	}

	@media screen and (max-width: $breakpoint-small){
		.hp-news__frame{
			width: 80vw;
			margin: 0 auto;
			overflow: visible;
		}
	
		.hp-news__slide{
			width: 80vw;
			padding: 0 2rem 0 0;
		}
	
		.hp-news__slide:first{	
			position: relative;
		}
	}
</style>