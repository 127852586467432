<template>
    <ul v-if="galleryAdsGroup.length > 0 && !isMobile" class="gallery-ads">
        <li class="gallery-ad--desktop" v-for="(ad, index) in galleryAdsGroup" v-html="ad"></li>
    </ul>
</template>
<script>
    import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';

    export default {
        computed: {
            ...mapGetters({isMobile: 'viewport/isMobile'}),
            ...mapState({
                galleryAds: (state) => state.ads.galleryAds.desktop ? state.ads.galleryAds.desktop : [],
            }),

            galleryAdsGroup: function(){
                return this.galleryAds[0] ? this.galleryAds[0] : [];
            }
        }
    };
</script>

<style lang="scss">
@import "scss/variables/breakpoints";
    .gallery-ads{
        text-align: center;
        border-left: 1px solid #dfdfdf;
        padding-left: 2rem;
        padding-top: 1rem;
        padding-bottom: 160px;
        margin-top: 0px;
        flex-shrink: 0;
    }

    .gallery-ad--desktop{
        width: 110px;
        margin-bottom: 50px;
    }

    @media screen and (max-width: $breakpoint-large){
        .gallery-ads {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }

    @media screen and (max-width: $breakpoint-medium + 16px){
        .gallery-ads,  .gallery-ad * {
            display: none;
        }
    }
</style>