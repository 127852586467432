<template>
    <section class="header" v-bind:class="{'header--isSticky': isMinimized, 'header--hiddenMobile': scrolledDown && !slideMenuIsActive}">
        <div :class="{'header__background': true, 'header__background--overlay': slideMenuIsActive}">
            <div class="header__top wrapper" :class="{'header__top--overlay': slideMenuIsActive}">
                <a class="top-navigation__logo" v-bind:class="{'top-navigation__logo--white': slideMenuIsActive}" :href="sitePath">
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 460.8 57.6" style="enable-background:new 0 0 460.8 57.6;" xml:space="preserve">
                        <g>
                            <defs>
                                <rect id="SVGID_1_" x="3.5" y="3.1" width="226.8" height="51.4"/>
                            </defs>
                            <clipPath id="SVGID_2_">
                                <use xlink:href="#SVGID_1_"  style="overflow:visible;"/>
                            </clipPath>
                            <path class="st0" d="M116.9,3.1c-4.4,0-7.8,1.2-10.1,3.5c-2.3,2.3-3.4,5.8-3.4,10.3v25c0,3.9,1.2,7,3.6,9.2
                                c2.4,2.2,5.7,3.4,9.9,3.4c4.2,0,7.6-1.1,10-3.2c2.4-2.1,3.7-5,3.7-8.7V16.9C130.5,7.7,126,3.1,116.9,3.1 M119.7,42
                                c0,1.6-1.3,2.8-2.8,2.8s-2.8-1.3-2.8-2.8V15.6c0-1.6,1.3-2.8,2.8-2.8s2.8,1.3,2.8,2.8V42z M61.7,3.9h20.6v9.6h-5v40.2H66.5V13.5
                                h-4.8V3.9z M83.5,3.9h18.9v9.6h-8.1v7.9h7.9v9.5h-7.9v22.9H83.5V3.9z M176.1,3.9h10.8v38.7c0,3.6-1.2,6.5-3.7,8.7
                                c-2.4,2.1-5.8,3.2-10,3.2c-4.2,0-7.5-1.1-9.9-3.4c-2.4-2.2-3.6-5.3-3.6-9.2V3.9h10.8V42c0,1.6,1.3,2.8,2.8,2.8
                                c1.6,0,2.8-1.3,2.8-2.8V3.9z M49.2,3.9H33.9v49.9h10.6v-23h2h1.2c0.8,0,1.4,0.6,1.4,1.4v21.7h10.7V35.3c0-2.6-0.2-4-0.8-5.1
                                s-1.8-2.1-3.4-2.5c0.9-0.2,1.7-0.5,2.4-1c0.6-0.5,1.2-1.2,1.6-2.1c0.4-0.9,0.7-2,0.9-3.3c0.2-1.3,0.3-2.9,0.3-4.7
                                c0-4.5-0.9-7.7-2.7-9.7C56.3,4.9,53.3,3.9,49.2,3.9 M49.6,20.8c-0.6,0.7-1.5,1.1-2.8,1.1h-2.3v-4.4V13h2.3c1.3,0,2.2,0.4,2.8,1
                                c0.6,0.7,0.9,1.8,0.9,3.4C50.5,18.9,50.2,20.1,49.6,20.8 M147.2,3.9h-15.3v49.9h10.6v-23h2h1.2c0.8,0,1.4,0.6,1.4,1.4v21.7h10.7
                                V35.3c0-2.6-0.2-4-0.8-5.1s-1.8-2.1-3.4-2.5c0.9-0.2,1.7-0.5,2.4-1c0.6-0.5,1.2-1.2,1.6-2.1c0.4-0.9,0.7-2,0.9-3.3
                                c0.2-1.3,0.3-2.9,0.3-4.7c0-4.5-0.9-7.7-2.7-9.7C154.2,4.9,151.2,3.9,147.2,3.9 M147.5,20.8c-0.6,0.7-1.5,1.1-2.8,1.1h-2.3v-4.4V13
                                h2.3c1.3,0,2.2,0.4,2.8,1c0.6,0.7,0.8,1.8,0.8,3.4C148.4,18.9,148.1,20.1,147.5,20.8 M10.5,3.9l-7,49.9h10.9l1-7.1h5.6l1,7.1h10.9
                                l-7-49.9H10.5z M15.8,37.2L18,19.9h0.3l2.2,17.3H15.8z M230.3,3.9v49.9h-10.4V21.8h-0.5l-5.2,31.9h-4.9h-4.9l-5.2-31.9h-0.5v31.9
                                h-10.4V3.9h16.2l4.7,22.4L214,3.9H230.3z"/>
                        </g>
                        <g>
                            <defs>
                                <rect id="SVGID_3_" y="0" width="460.8" height="57.6"/>
                            </defs>
                            <clipPath id="SVGID_4_">
                                <use xlink:href="#SVGID_3_"  style="overflow:visible;"/>
                            </clipPath>
                            <path class="st1" d="M251.4,4.6h6.1c0,0,0.9,0.1,1.1,0.8c0.2,0.7-0.3,1.4-0.5,1.9c-0.2,0.5-0.2,3.2-0.2,3.2h8.6l0-5.9h7
                                c0,0,0.6,0.2,0.6,0.7c0.1,0.7-0.4,1.4-0.7,2c-0.3,0.6-0.2,3.2-0.2,3.2H286v6h-12.8v4.9h-6.6v-4.9h-8.8v4.9h-6.4v-4.9h-12.5v-5.8
                                h12.5V4.6z"/>
                            <path class="st1" d="M246.8,23.3l0.1,5.6h13.7v0.4c0,0.4-5.9,5.5-7.8,7.2c-1.9,1.7-7.6,7.6-7.2,11.8c0.4,4.2,4.4,4.6,5.3,4.6H280
                                c0,0,2.9-0.4,4.4-3c1.6-2.5,2.1-7.8,2.1-7.8l-7.4-1.8c0,0,0,2.9-1,5c-1.1,2.1-3,1.7-4,1.7h-19.6c0,0-0.8-0.4-0.8-1.5
                                c0-1.1,4.4-5.9,8.4-9.1c4-3.2,15.4-13.2,15.4-13.2H246.8z"/>
                            <path class="st1" d="M322.8,4.9h-7.4v12.8h-18.6v6.1h14.3c0,0-2.5,7.3-6.7,12.5c-4.2,5.2-9.6,9.4-9.6,9.4l6.1,4c0,0,5.9-5.6,9-10
                                c3.1-4.4,5.6-8.9,5.6-8.9v22.7h7.3V29.7c0,0,3,7.1,7.2,11.6c4.2,4.5,9.4,7.4,9.4,7.4l5.8-5.8c0,0-8-3.3-11.7-8
                                c-3.8-4.7-5.3-8.4-5.7-9.8c-0.5-1.3-0.6-1.3-0.6-1.3h14.8l0.1-6.1h-19.2L322.8,4.9z"/>
                            <path class="st1" d="M326.2,8.4l6.4-2.9c0,0,1,0.9,1.8,2.4c0.8,1.4,2.8,5.5,2.8,5.5l-6.2,3c0,0-2.6-5-3-5.4
                                C327.7,10.5,326.2,8.4,326.2,8.4"/>
                            <path class="st1" d="M361.4,4.9l-7.3,3.4c0,0,2,3.1,2.9,4.6c0.8,1.4,2.4,5.2,2.4,5.2l7.2-2.8c0,0-1.5-3.8-2.7-6.2
                                C363.2,7.4,361.4,4.9,361.4,4.9"/>
                            <path class="st1" d="M349.8,20.8h13.8v17.9l5.2-4.9c0,0,1.2,3.2,1.4,3.7c0.3,0.5,1.5,2.8,1.5,2.8l-13.1,12.9l-5.4-6.3
                                c0,0,2.1-2.6,2.6-3.4c0.5-0.8,0.6-1.8,0.6-1.8v-15h-6.7V20.8"/>
                            <path class="st1" d="M382.8,17.6c0,0-0.9-1.3-1.1-1.6c-0.2-0.3-0.7-0.8-0.7-0.8s-1.3,2.1-1.7,2.7c-0.4,0.6-3.4,5.4-3.4,5.4h3.9h1.1
                                l-0.5,8.8c0,0,3.6-2.3,5.2-3.8c1.7-1.5,2.5-2.2,2.5-2.2s3.2,2.9,3.8,3.6c0.5,0.7,2,2.6,2,2.9c0,0.2-13,6.8-13,6.8l-0.3,0.3v6.2
                                c0,0,0.1,0.6,0.4,0.9c0.3,0.3,0.6,0.6,1.1,0.6h6.6c0,0,0.7-0.5,0.9-1.1c0.2-0.6,0.5-2.2,0.6-3c0.1-0.8,0.1-3.8,0.1-3.8
                                s1.5,1.1,3,1.7c1.5,0.6,3.2,1.2,3.7,1.3c0.4,0.1,0.8,0.1,0.8,0.1s0,3.7-0.8,6.3c-0.8,2.6-2.2,3.9-2.8,4.4c-0.6,0.5-0.8,0.5-0.8,0.5
                                h-14.7c0,0-1.4-0.1-2.9-0.9c-1.5-0.8-2.1-1.8-2.1-2.5c0-0.7,0-23.7,0-23.7s-1.4,1.8-1.8,2.2c-0.5,0.4-1.1,1.2-1.1,1.2
                                s-2.4-1.8-3.3-2.1c-0.9-0.3-3.6-1.5-3.6-1.5s3.9-4,4.6-4.9c0.8-0.9,3.6-5.3,4.9-7.9c1.3-2.7,2.5-6.2,2.6-7.3s0.6-2.4,0.6-2.4
                                s1.9,0.4,4.7,0.8c2.8,0.3,5.2,0.9,5.2,0.9s-0.8,1.2-1.1,1.8c-0.3,0.6-0.1,0.6,0.3,1.6s1.6,2.7,2.7,4.1c1.1,1.4,3.1,3.4,4.8,4.7
                                c1.7,1.3,4.7,3,6.1,3.7l1.3,0.8c0,0-1.7,2.4-2.4,3.7c-0.8,1.3-2.4,4.1-2.4,4.1s-5.1-3.8-6.7-5.3C387.6,23.2,383.3,18.4,382.8,17.6"
                                />
                            <path class="st1" d="M420.6,4.5c0,0,0.5,0,0.5,0.6c0,0.4-0.1,12.4-0.1,12.4h6.1c0,0,0,4.1,0.1,4.3c0.1,0.2,29.3,0.1,29.3,0.1
                                s0.1,6.1,0.1,6.2c0,0.1-12.6,0.1-12.6,0.1s-3.9,8.2-4.9,10.2c-1.1,2-3.1,5.9-3.1,5.9s9.5-0.1,9.7-0.1c0.2,0-3.8-7.3-3.8-7.3
                                l6.2-3.3c0,0,4.7,7.9,5.6,9.7c0.9,1.9,3.6,7.5,3.6,7.5s-3.7,1.6-4.3,1.9c-0.6,0.3-3,1.1-3,1.1l-1.7-3.9c0,0-10,0.5-13.1,1.1
                                c-3.1,0.5-8.7,1.2-8.7,1.2l-1.9-6.9c0,0,2.2-0.3,2.8-0.6c0.7-0.3,1.2-0.6,3.1-3.8c1.3-2.2,3.8-9.5,4.6-12c0.2-0.5,0.2-0.8,0.2-0.8
                                h-9.5v-4.3h-4.7v12.3c0,0,2.8-0.6,3.5-0.8c0.8-0.2,3.5-1.1,3.5-1.1h0.1v6.3c0,0-7.9,2.3-9.8,2.9c-1.9,0.6-9.4,3.3-9.4,3.3
                                s-1.1-3.7-1.3-4.3c-0.3-0.6-1.1-3.8-1.1-3.8s3-0.1,4-0.3c1.1-0.2,3.4-0.8,3.4-0.8V23.8h-6.7v-6.4h6.7V4.5H420.6z"/>
                        </g>
                        <rect x="428.7" y="7.5" width="24.5" height="6.3"/>
                    </svg>

                </a>
                <desktop-search class="search" :class="{'search--hiddenMobile': slideMenuIsActive}"></desktop-search>
                <mobile-search></mobile-search>
                <slide-navigation :navigation="navigation">
                    <menu-extras :menu-active="slideMenuIsActive"></menu-extras>
                </slide-navigation>
            </div>
        </div>
        <hr class="border search__border" :class="{'desktop-only': slideMenuIsActive}">
        <top-navigation :navigation="navigation" :hidden="topNavIsHidden"></top-navigation>
    </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import slideNavigation from 'shared/components/navigation/SlideNavigation.vue';
import topNavigation from 'shared/components/navigation/TopNavigation.vue';
import menuExtras from './navigation/MenuExtras.vue';
import desktopSearch from './search/DesktopSearch.vue';
import mobileSearch from './search/MobileSearch.vue';

import NAVIGATION from 'shared/constants/Navigation';
import { SITE_PATH } from 'shared/constants/Paths';

export default {
    data () {
        return {
            isScrolledPast: false,
            sitePath: SITE_PATH,
            navigation: NAVIGATION,
        }
    },
    
    computed: {
        ...mapState('viewport', ['scrollY', 'scrolledDown']),
        ...mapState('ads', ['leaderboardHeight']),
        ...mapState('artguide', ['isArtguideShowing']),
        ...mapGetters(['searchIsActive', 'slideMenuIsActive']),
        positionY(){
            //return this.$el.offsetTop;
            return document.getElementsByClassName('leaderboard-ad')[0].offsetHeight;
        },
        isMinimized(){
            return this.isScrolledPast;
        },
        topNavIsHidden(){
            return (this.isScrolledPast && this.scrolledDown);
        }
    },

    watch: {
        // moved this from computed properties to fix an error that this.$el was not defined
        scrollY: function(scrollPos){
            this.isScrolledPast = scrollPos > this.leaderboardHeight;
        }
    },

    components: {
        slideNavigation,
        topNavigation,
        desktopSearch,
        mobileSearch,
        menuExtras,
    }
};

</script>
