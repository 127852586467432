import NAVIGATION from 'shared/constants/Navigation';
import ADMIN_NAVIGATION from '../../../admin/constants/AdminNavigation';
import _map from 'lodash/map';

const navigation = {
	namespaced: true, 

	state: {
		category: '',
		tag: '',
		isAdmin: false,
	},

	getters: {
		parentCategory: function(state){
			if ( !state.category ){
				return '';
			}
			const NAV = state.isAdmin ? ADMIN_NAVIGATION : NAVIGATION;
			for ( let i = 0; i < NAV.PAGES.length; i++ ) {
				let page = NAV.PAGES[i];
				let subpageNames = _map(page.subpages, (subpage) => { return subpage.name.toLowerCase() });
				if (subpageNames.indexOf( state.category.toLowerCase() ) > -1 ){
					return page.name;
				}
			}
			return '';
		},
	},
	
	mutations: {
		setCategory(state, category){
			state.category = category;
		},

		setTag(state, tag){
			state.tag = tag;
		},

		setIsAdmin(state, val){
			state.isAdmin = val;
		},
	},
}

export default navigation;