const prevent = function(e){
	e = e || window.event;
    var target = e.target || e.srcElement;
    //in case $altNav is a class:
    if (!target.className.match(/\blightbox__image\b/))
    {
        e.returnValue = false;
        e.cancelBubble = true;
        if (e.preventDefault)
        {
            //e.preventDefault();
            //e.stopPropagation();
        }
        return false;//or return e, doesn't matter
    }
}

export default {
	disableScroll: function(){
		document.body.classList.add('noscroll');
		// document.body.addEventListener('touchmove', prevent, false);
	},

	enableScroll: function(){
        if( document.body.classList.contains('noscroll') ) {
		  document.body.classList.remove('noscroll');
        }
		// document.body.removeEventListener('touchmove', prevent, false)
	},

    /* 
     *  this rule can be removed after Apple fixes this bug:
     *  https://bugs.webkit.org/show_bug.cgi?id=176896
     */
    iosFormFix: function(){
        document.body.classList.add('iosFormFix');
    },

    removeIosFormFix: function(){
        if( document.body.classList.contains('iosFormFix') ) {
            document.body.classList.remove('iosFormFix');
        }
    }
}