<template>
	<a href="#" v-on:click.prevent="loginLogout" :tabindex="tabIndex">{{linkText}}</a>
</template>

<script>
	import { mapState, mapMutations, mapActions } from 'vuex';
	import MODALS from 'shared/constants/Modals';
    import activeTabindex from 'shared/components/mixins/navigation/activeTabindex';
	
	export default {
        mixins: [activeTabindex],
		computed: {
        	...mapState({
                userIsLoggedIn: state => state.user.userIsLoggedIn
        	}),

        	linkText: function(){
        		return this.userIsLoggedIn ? 'SIGN OUT' : 'SIGN IN'
        	}
    	},

    	methods: {
    		...mapActions('user', ['userLogout']),

    		...mapMutations(['openModal']),

    		openLoginForm: function(){
            	this.openModal(MODALS.LOGIN_FORM);
        	},

        	loginLogout: function(){
        		if ( this.userIsLoggedIn ) {
        			this.userLogout();
        		}
        		else {
        			this.openLoginForm();
        		}
        	}
    	}
	}
</script>