<template>
	<div v-show="leaderboardAd && leaderboardAd.length > 0" class="leaderboard-ad" v-html="leaderboardAd"></div>
</template>
<script>
	import { mapMutations, mapState } from 'vuex';
	export default {
		computed: {
			...mapState({
				leaderboardAd: (state) => state.ads.leaderboardAd
			})
		},

		methods: {
			...mapMutations('ads', ['adsAssign'])
		},

		watch: {
			leaderboardAd: function(){
				this.$nextTick( () => {
					this.adsAssign({leaderboardHeight: this.$el.offsetHeight});
				})
			}
		}
	};
</script>

<style lang="scss">

	@import "scss/variables/breakpoints";

	.leaderboard-ad{
		background: #f5f5f5;
		text-align: center;
	}

	@media screen and (min-width: $breakpoint-medium) {
		.leaderboard-ad{
			padding: 1rem 0px;
		}

		.leaderboard-ad .Ad{
			max-width: 970px;
			height: 180px;
			margin: 0 auto;
		}
	}

	@media screen and (max-width: $breakpoint-medium) {
		.leaderboard-ad .Ad {
			height: 0px;
			padding-bottom: 18.556701030928%;
		}
	}
</style>