import { API_PATH, ARTFORUM_API_URL } from 'shared/constants/Paths';
import auth       from 'lib/auth';

const user = {
	namespaced: true,

	state: {
		hasCheckedLogin: false,
		userIsLoggedIn: false,
        username: '',
		loginMessage: '',
		registrationMessage: '',
        registrationResponseStatus: null,
        registrationError: false,
        passwordRecoverySent: false,
        passwordRecoveryMessage: '',
	},

	mutations: {
		setLoginStatus(state, status){
			state.hasCheckedLogin = true;
            state.userIsLoggedIn = status;
        },

        setLoginMessage(state, message){
            state.loginMessage = message;
        },

        setRegistrationMessage(state,message){
            state.registrationMessage = message;
        },

        setRegistrationError(state, bool){
            state.registrationError = bool;
        },

        setRegistrationResponseStatus(state, status){
            state.registrationResponseStatus = status;
        },

        setPasswordRecoverySent(state, status){
            state.passwordRecoverySent = status;
        },

        setPasswordRecoveryMessage(state, message){
            state.passwordRecoveryMessage = message;
        },

        setUsername(state, username){
            state.username = username;
        }

    },

    actions: {

        registerNewUser({commit, state}, options){

            return new Promise((resolve, reject) => {
                const data = JSON.stringify(options);

                const headers = new Headers({
                    'Content-Type': 'text/plain;charset=UTF-8',
                    'Accept'      : 'application/json'
                });

                commit('addActiveCall', 'registerNewUser', { root: true });

                fetch(ARTFORUM_API_URL + '/user/create', {
                    method : 'POST',
                    headers: headers,
                    body   : data
                }).then((res) => {
                    const errStatus = res.ok ? false : true;
                    commit('setRegistrationError', errStatus);
                    commit('setRegistrationResponseStatus', res.status);
                    return res.json();
                }).then((data) => {
                    if(state.registrationResponseStatus > 401){
                        // we send back a custom .cn message for 401s
                        commit('setRegistrationMessage', '处理您的请求时出错。请联系circulation@artforum.com求助。')
                    }
                    else if (data.message) {
                        commit('setRegistrationMessage', data.message);
                    }
                    commit('removeActiveCall', 'registerNewUser', { root: true });
                    resolve(true);
                }).catch((err) => {
                    commit('setRegistrationMessage', '处理您的请求时出错。请联系circulation@artforum.com求助。')
                    commit('removeActiveCall', 'registerNewUser', { root: true });
                    reject(false);
                });
            })
        },

        userLogin({ commit, dispatch }, creds){
            const LOGIN_URL = API_PATH + '/login_check';

            const headers = new Headers({
                'Content-Type': 'application/json',
                'Accept'      : 'application/json',
            });

            commit('addActiveCall', 'userLogin', { root: true });

            fetch(LOGIN_URL, {
                method     : 'POST',
                headers    : headers,
                credentials: 'same-origin',
                body       : JSON.stringify(creds)
            }).then((res) => {
                if (!res.ok) {
                    throw new Error('You have entered an invalid username or password. Please try again.')
                }
                return res.json();
            }).then((data) => {
                auth.setToken(data.token)
                if ( auth.checkAuth() ) {
                    commit('setUsername', data.data.username);
                    commit('setLoginStatus', true);
                    commit('setLoginMessage', 'Success!');
                    commit('removeActiveCall', 'userLogin', { root: true });
                    //dispatch('syncCart', null, { root: true }); - not necessary when reloading page. leaving here for reference in case.
                    window.location.reload(true);
                }
            }).catch((err) => {
                commit('setLoginMessage', err.message);
                commit('removeActiveCall', 'userLogin', { root: true });
                return(false);
            })
        },

        userLogout({ commit }){
            const LOGOUT_URL = API_PATH + '/logout';

            const headers = new Headers({
                'Accept': 'application/json',
            });

            return fetch(LOGOUT_URL, {
                headers: headers,
                credentials: 'include'
            }).then((res) => {
                if (res.ok) {
                    commit('setLoginStatus', false);
                    window.location.reload(true);
                }
            });
        },

        checkLoginStatus({ commit }){
            const TOKEN_CHECK_URL = API_PATH + '/token_check';

            const headers = new Headers({
                'Accept': 'application/json',
            });

            return fetch(TOKEN_CHECK_URL, {
                headers: headers,
                credentials: 'include'
            }).then((res) => {
                if (res.ok) {
                    commit('setLoginStatus', true);
                }
                else {
                    commit('setLoginStatus', false);
                }
            }).catch((error) => {
                console.warn(error);
            });
        },

        requestPasswordReset({ commit }, options){
            const PASSWORD_RESET_URL = API_PATH + '/user/recover';

            const data = JSON.stringify(options);

            const headers = new Headers({
                'Accept': 'application/json'
            });

            commit('addActiveCall', 'requestPasswordReset', { root: true });

            fetch(PASSWORD_RESET_URL, {
                method: 'POST',
                headers: headers,
                body: data
            }).then((res) => {
                if (res.ok) {
                    commit('setPasswordRecoverySent', true);
                }
                return res.json();
            }).then((data) => {
                if ( data.errorMessage ){
                    commit('setPasswordRecoveryMessage', data.errorMessage);
                }
                commit('removeActiveCall', 'requestPasswordReset', { root: true });
            }).catch((err) => {
                commit('removeActiveCall', 'requestPaswordReset', {root: true});
            });
        }
	}
}

export default user;
