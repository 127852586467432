<template>
	<div class="g-recaptcha recaptcha-container" :id="id"></div>
</template>
<script>
	import { mapState } from 'vuex';
	import { RECAPTCHA_API_KEY } from '../../constants/APIKeys';
	export default {
		props: {
			callback: Function,
			errorCallback: Function,
			id: String
		},
		
		data: function(){
			return {
				sitekey: RECAPTCHA_API_KEY,
				recaptchaRef: ''
			}
		},
		computed: {
			...mapState('reCaptcha', ['recaptchaIsLoaded'])
		},
		methods: {
			reset: function() {
				grecaptcha.reset(this.recaptchaRef);
			},
			renderRecaptcha: function() {
				this.recaptchaRef = grecaptcha.render(this.id, { sitekey: this.sitekey, callback: this.callback });
			}
		},
		watch: {
			recaptchaIsLoaded: function(){
				this.renderRecaptcha();
			}
		}
	};
</script>

<style>
	.recaptcha-container{
		display: inline-block;
	}
</style>