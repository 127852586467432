<template>
	<li :class="{bigbold: isLowercase}" :key="page.name">
		<a class="nav__link" :href="url" v-on:click="toggle($event)" :target="target" :rel="rel" :tabindex="tabIndex">{{page.name}}</a>
		<a class="toggle-link" v-if="isExpandable" v-on:click="toggle" v-on:keyup.enter="toggle" :tabindex="tabIndex"> 
			<img class="toggle-link__img" v-bind:class="{'toggle-link__img--expanded': isExpanded}" src="~img/arrow_down_white.svg" alt="toggle submenu"> 
		</a>
		<ul class="subnav" v-if="renderDeep">
			<nav-item-slide v-for="subpage in subpagesFiltered" :key="subpage.name" :page="subpage" :deep="false" class="subnav__link">
			</nav-item-slide>
		</ul>
	</li>
</template>

<script>
import navItem from './mixins/NavItem';
import activeTabindex from 'shared/components/mixins/navigation/activeTabindex';

export default {
	name: 'nav-item-slide',
	mixins: [navItem, activeTabindex]
};
</script>

<style lang="scss" scoped>
@import "scss/variables/typography";

.toggle-link{
	float: right;
	color: #fff;
	padding-right: 1em;
	font-size: 1.5em;
	margin-top: -.25em;
}
.nav__link{
	color: #fff;
}

.subnav__link a{
	color: #444;
	font-weight: normal;
}

.toggle-link:hover, .nav__link:hover{
	cursor: pointer;
}

.toggle-link__img{
	height: .625rem;
	width: .75rem;
}

.toggle-link__img--expanded{
	transform: scale(1, -1);
}

.subnav{
	padding-left: .5rem;
	margin-left: 0px;
}
</style>