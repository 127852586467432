<template>
    <af-overlay v-if="showAd" :dark="true">
        <a :href="popupAdUrl" alt="Subscribe">
            <img :src="popupAd" class="popup-ad">
        </a>
    </af-overlay>
</template>
<script>
    import { mapState, mapGetters } from 'vuex';
    import overlay from 'shared/components/container/Overlay.vue';

    export default {
        computed: {
            ...mapState({
                popupAd: (state) => state.ads.popupAd,
                popupAdUrl: (state) => state.ads.popupAdUrl,
                counter: (state) => state.ads.popupAdCounter,
            }),
            ...mapGetters(['popupAdIsActive']),
            showAd: function(){
                return this.popupAdIsActive;
            }
        },
        components: { 'af-overlay': overlay }
    };
</script>
<style lang="scss">
    @import "scss/variables/breakpoints";

    .popup-ad{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 50vw;
        height: 50vw;
        max-width: 600px;
        max-height: 600px;
    }
    @media (max-width: $breakpoint-medium){
        .popup-ad{
            width: 90vw;
            height: 90vw;
        }
    }
</style>