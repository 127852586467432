import srcsetFromMedia from 'shared/components/mixins/SrcsetFromMedia';
import formatDate from 'shared/components/mixins/FormatDate'; 
import stripTags from 'shared/components/mixins/StripTags';
import { SITE_PATH } from 'shared/constants/Paths';

export default {
    mixins: [srcsetFromMedia, formatDate, stripTags],
    props: {
        article: {
            type: Object,
            required: true,
        },
        showChannel: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    computed: {
        channelLink: function(){
            return `${SITE_PATH}/${this.article.channel}`;
        }
    },
};