import { API_PATH, SITE_PATH }from 'shared/constants/Paths';
import MODALS from 'shared/constants/Modals';

import { mapGetters, mapState, mapActions, mapMutations } from 'vuex';

export default {
	data(){
		return {
			inputValue: '',
			searchPath: SITE_PATH + '/search'
		}
	},

	methods: {
		...mapMutations(['openModal', 'closeModal']),

		preventIfEmpty: function(e){
			if (this.inputValue === ''){
				e.preventDefault();
				return false;
			}
		}
	},
}