<template>
	<button class="form__submit" :class="{'form__submit--activeState': isActive}" @click.prevent="submit()">{{ buttonText }}</button>
</template>
<script>
	import activeState from '../mixins/ActiveState';
	export default {
		props: {
			callback: Function,
			associatedAction: String,
			buttonText: String,
			disabled: {
				type: Boolean,
				required: false,
				default: false
			}
		},
		methods: {
			submit: function(){
				if (!this.isActive && !this.disabled) {
					this.callback();
				}
			}
		},
		mixins: [activeState]
	};
</script>

<style lang="scss">
	.form__submit--activeState{
		opacity: .5;
	}
</style>