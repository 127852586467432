<template>
	<form class="search__module" v-on:submit="preventIfEmpty" role="search">
		<div class="search__controls" v-if="isActive">
			<label for="desktop_search_input" class="visually-hidden">search</label>
			<input id="desktop_search_input" ref="searchInput" class="search__input" v-model="inputValue" autocomplete="off" type="search" name="search"></input>
			<button class="search__submit" type="submit" id="searchSubmit" :formaction="searchPath">SUBMIT</button>
		</div>
		<search-toggle class="search__toggle" :callback="toggle" :active="isActive"></search-toggle>
	</form>
</template>
<script>
import { API_PATH }from 'shared/constants/Paths';
import searchToggle from './SearchToggle.vue';
import searchField from './mixins/SearchField';

import MODALS from 'shared/constants/Modals';
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex';

export default{
	computed: {
		...mapGetters({
			isActive: 'searchIsActive'
		})
	},

	methods:{

		toggle: function(){
			if( !this.isActive ){
				this.openModal(MODALS.SEARCH);
				document.addEventListener('mousedown', this.closeOnBlur);
			}
			else{
				document.removeEventListener('mousedown', this.closeOnBlur);
				this.$nextTick(function() { this.closeModal(); });
			}
		},

		closeOnBlur: function(e){
			if ( e.target.classList.contains('search-suggestion__link') || e.target.classList.contains('search__input') ) return;
			this.toggle();
		},
	},

	watch: {
		isActive: function(active){
			if(active){
				this.$nextTick(function(){
					this.$refs.searchInput.focus();
				})
			}
		}
	},

	components: {
		searchToggle,
	},

	mixins: [ searchField ]
};
</script>

<style lang="scss">
@import "scss/variables/breakpoints";
@import "scss/variables/grid";
@import "scss/variables/typography";

.search__module{
	position: relative;
	font-size: 1.25rem;
	flex-grow: 1;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
}

.search__controls{
	flex-grow: 1;
}

@media screen and (max-width: $breakpoint-medium){
	.search__module{
		display: none;
	}
}


</style>