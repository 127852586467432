<template>
	<div class="footer__signup">
		<h4 class="footer__signup-header">ARTFORUM 收件箱</h4>
		<p class="footer__signup-note">提供您的电子邮件地址以获得更多相关资讯，定期了解杂志内容以及特别优惠。您可以随时取消订阅。</p>
		
		<dot-dot-dot associated-action="registerNewUser" :invert-color="true"></dot-dot-dot>

		<div class="footer__signup-form" v-show="showForm">
			<form v-show="showForm">
				<div class="flex">
					<div class="register-footer__input-container">
						<label for="register_footer_email" class="visually-hidden">email</label>
						<input 
							id="register_footer_email"
							class="footer__signup-input" 
							name="email" type="text"
							placeholder="E-MAIL" 
							v-model="userEmail"
							v-on:focus="clearMessages" />
						<div class="footer__recaptcha" v-if="userEmail.length">
							<img :src="quizSrc" alt="2 + 5 = ?"><br>
							<label for="quizSolution">请回答如下问题。</label>
							<input id="quizSolution" class="footer__signup-input"  type="text" v-model="quizSolution">
						</div>
					</div>
					<button 
						class="footer__signup-submit" 
						type="submit" 
						@click.prevent="submitForm()">提交</button>
				</div>
				<div class="form__field-error form__field-error--white" v-html="error" v-if="error.length" role="alert"></div>
			</form>
			<p class="form__message--error form__field-error--white" v-html="registrationMessage" v-if="registrationMessage.length" role="alert"></p>
		</div>
	</div>
</template>
<script>
	import { mapActions, mapState, mapMutations } from 'vuex';
	import dotDotDot from '../animations/DotDotDot.vue';
	import { IMAGE_BASE_PATH } from 'shared/constants/Paths';

	export default {
		props: {
			origin: {
				type: String,
				required: false,
				default: 'cn_footer',
			},
		},

		data(){
			return {
				userEmail: '',
				error: '',
				success: false,
				quizSolution: '',
			}
		},
		computed:{
			...mapState({
				registrationMessage: (state) => state.user.registrationMessage
			}),

			...mapState(['activeCalls']),

			showForm: function(){
				return ( this.activeCalls.indexOf('registerNewUser') < 0 ) && !this.success;
			},

			quizSrc: function(){
				return `${IMAGE_BASE_PATH}/static-assets/quiz.png`;
			}
		},
		methods: {
			...mapActions('user', ['registerNewUser']),

			...mapMutations('user', ['setRegistrationMessage']),

			clearMessages: function() {
				this.setRegistrationMessage('');
			},

			submitForm: function(){
				const user = { email: this.userEmail, origin: this.origin };
				const data = { user, solution: this.quizSolution };

				if(!this.quizSolution.length){
					this.setRegistrationMessage('Please complete the quiz');
					return;
				}
				
				this.registerNewUser(data)
				.then((res)=>{
					this.userEmail = '';
					if ( res.ok ){
						this.success = true
					}
				})
				.catch((err) => {
					this.error = '';
					this.setRegistrationMessage(err);
				});
			}
		},

		watch: {
			quizSolution: function(val){
				if(val.length){
					this.setRegistrationMessage('');
				}
			}
		},

		components: {
			dotDotDot,
		},
	};
</script>

<style>
	.footer__recaptcha{
		margin-top: .5rem;
	}

	.footer__signup-header{
		font-weight: 100;
	}

	.footer__signup .form__message{
		color: #000;
	}

	.register-footer__input-container{
		flex-grow: 1; 
		margin-right: 1rem;
	}

	.register-footer__input-container .footer__signup-input{
		width: 100%;
	}
</style>