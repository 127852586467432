import _throttle from 'lodash/throttle';

export default {
	data(){
		return {
			lastScrollPosition: 0,
			scrolledDown: false
		}
	},
	methods: {
		onScroll: _throttle(function(event){
			let scrolledDown = event.target.scrollTop > this.lastScrollPosition && event.target.scrollTop > 0;
			this.scrolledDown = scrolledDown;
			this.lastScrollPosition = event.target.scrollTop;
			this.$forceUpdate();
		}, 150)
	}
}