<template>
	<section class="hp-section">
		<news-carousel :news-link="newsLink" :news-items="newsItems" v-if="newsItems.length > 0"></news-carousel>
	</section>
</template>
<script>
	import { mapState, mapActions, mapGetters } from 'vuex';
	import { WEBROOT } from 'shared/constants/Paths';
	import NewsCarousel from './news/NewsCarousel.vue';

	export default {
		data: function(){
			return {
				newsLink: WEBROOT + '/news'
			}
		},
		computed: {
			...mapState({
				newsItems: state => state.news.frontPageNews,
			}),

			...mapGetters({
				isMobile: 'viewport/isMobile'
			})
		},
		methods: {
			...mapActions('news', ['getFrontPageNews'])
		},
		mounted: function(){
			this.$nextTick(function(){
				this.getFrontPageNews();
			})
		},
		components: { NewsCarousel },
	};
</script>