import getClosest from './GetClosest';

/**
 * Create a click event listener that will execute a given callback if the event target is not in the array of given elements.
 * 
 * @param {Array} selectors     an array of selectors to be checked against
 * @param {Function} callback   the function to call if the click was outside of the chosen selectors
 *
 * @return Function
 */
export default function(selectors, callback){
	return function(event){
		
		var targetIsInSelectors = selectors.map((selector) =>
			getClosest(event.target, selector)
		).reduce((prev, current) => prev || current);

		if (!targetIsInSelectors) {
			callback();
		}
	}
}