import KEYCODES from '../../constants/Keycodes';
import getClosest from 'lib/utils/GetClosest';
import createClickOutsideHandler from 'lib/utils/CreateClickOutsideHandler';

import MODALS from 'shared/constants/Modals';

export default function(store){

    const closeModal = function(){
        store.commit('closeModal');
    }

    // const toggleMenuOnClickOutside = function(event){
    //     if(!getClosest(event.target, '#slide-navigation')){
    //         store.commit('closeModal');
    //     }
    // }

    const toggleMenuOnClickOutside = createClickOutsideHandler(['#slide-navigation'], closeModal);

    // const toggleLightboxOnClickOutside = function(event){
    //     if(! ( getClosest(event.target, '.lightbox__content--visible') || getClosest(event.target, '.lightbox__navigation')) ){
    //         store.commit('closeModal');
    //     }
    // }

    const toggleLightboxOnClickOutside = createClickOutsideHandler(['.lightbox__content--visible', '.lightbox__navigation'], closeModal);

    const escClosesModal = function(e){
        if (e.keyCode === KEYCODES.ESC) {
            store.commit('closeModal');
        }
    }

    const rightAdvancesLightbox = function(e){
        if(e.keyCode === KEYCODES.RIGHT){
            store.commit('lightbox/lightboxNextImage');
        }
    }

    const leftDecrementsLightbox = function(e){
        if(e.keyCode === KEYCODES.LEFT){
            store.commit('lightbox/lightboxPreviousImage');
        }
    }

    store.subscribe( (mutation, state) => {
        if ( mutation.type === 'openModal' ) {

            window.addEventListener('keyup', escClosesModal);
            
            switch(mutation.payload){
                case MODALS.SLIDE_MENU:
                    window.addEventListener('mouseup', toggleMenuOnClickOutside);
                    break;

                case MODALS.SEARCH:
                    break;

                case MODALS.LIGHTBOX:
                    window.addEventListener('keyup', rightAdvancesLightbox);
                    window.addEventListener('keyup', leftDecrementsLightbox);
                    window.addEventListener('mouseup', toggleLightboxOnClickOutside);
                    break;

                case MODALS.LOGIN_FORM:
                case MODALS.REGISTER_FORM:
                    window.removeEventListener('mouseup', toggleMenuOnClickOutside);    // because there is a link to open this form in the menu
                    break;
            }
        }

        else if ( mutation.type === 'closeModal' ) {
            window.removeEventListener('mouseup', toggleMenuOnClickOutside);
            window.removeEventListener('keyup', escClosesModal);
            window.removeEventListener('mouseup', toggleLightboxOnClickOutside);
            window.removeEventListener('keyup', rightAdvancesLightbox);
            window.removeEventListener('keyup', leftDecrementsLightbox);
        }
    });
}