import { ADMIN_API_PATH, ADMIN_SITE_PATH } from 'shared/constants/Paths';

export default {
    ADMIN_SITE_PATH: ADMIN_SITE_PATH,
    PAGES: [
        {
            name: 'Articles',
            path: '/admin/articles',
            top: true,
        },
        {
            name: 'New Article',
            path: '',
            top: true,
            subpages: [
                {
                    name: 'News',
                    path: '/admin/article/create?type=5'
                },
                {
                    name: 'Picks',
                    path: '/admin/article/create?type=25'
                },
                {
                    name: 'Interviews',
                    path: '/admin/article/create?type=8'
                },
                {
                    name: 'Diary',
                    path: '/admin/article/create?type=46'
                },
                {
                    name: 'Print Issue',
                    path: '/admin/article/create?type=105'
                },
                {
                    name: 'Print Article',
                    path: '/admin/article/create?type=115'
                },
                {
                    name: 'Column',
                    path: '/admin/article/create?type=49'
                },
                {
                    name: 'Video',
                    path: '/admin/article/create?type=85'
                }
            ]
        },
        {
            name: 'Issues',
            path: '/admin/articles?type=105',
            top: true
        },
        {
            name: 'Homepage',
            path: '/admin/appearance/homepage',
            top: true,
        },
        {
            name: 'Users',
            path: '/admin/users',
            top: true,
        },
        {
            name: 'Artguide',
            path: '',
            subpages: [
                {
                    name: 'Locations',
                    path: '/admin/locations/list'
                }
            ],
            top: false
        },
        {
            name: 'Legacy CMS',
            path: '/admin.php',
            top: false,
        },
        {
            name: 'Help',
            path: '/admin/help',
            top: true,
        }
    ]
}