import Vue from 'vue';
import Vuex from 'vuex';
import isArray from 'lib/utils/IsArray';

// plugins
import viewportPlugin from 'shared/store/plugins/Viewport';
import location from './plugins/Location';
import keyListeners from './plugins/KeyListeners';
import shippingCountry from './plugins/ShippingCountry';
import adsPlugin       from './plugins/Ads';

// constants
import MODALS from 'shared/constants/Modals';

// modules
import news            from './modules/news';
import bookforum       from './modules/bookforum';
import lightbox        from './modules/lightbox';
import viewport        from 'shared/store/modules/viewport';
import user            from 'shared/store/modules/user';
import ads             from './modules/ads';
import relatedArticles from './modules/relatedArticles';
import reCaptcha       from './modules/reCaptcha';
import articles        from './modules/articles';
import navigation      from 'shared/store/modules/navigation';
import login           from './modules/login';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        activeModal:                   '',
        activeCalls:                   []
    },

    getters: {
        // in case a generic function would be cleaner?
        modalIsActive: (state) => (modal) => {
            return state.activeModal === modal;
        },
        slideMenuIsActive: (state) => state.activeModal === MODALS.SLIDE_MENU,
        registerFormIsActive: (state) => state.activeModal === MODALS.REGISTER_FORM,
        searchIsActive: (state) => state.activeModal === MODALS.SEARCH,
        lightboxIsVisible: (state) => state.activeModal === MODALS.LIGHTBOX,
        loginFormIsVisible: (state) => state.activeModal === MODALS.LOGIN_FORM,
        mobileSearchIsActive: (state) => state.activeModal === MODALS.MOBILE_SEARCH,
        popupAdIsActive: (state) => state.activeModal === MODALS.POPUP_AD,
    },

    mutations: {
        openModal(state, modal){
            state.activeModal = modal;
        },

        closeModal(state){
            state.activeModal = '';
        },

        addActiveCall(state, callName){
            state.activeCalls.push(callName);
        },

        removeActiveCall(state, callName){
            state.activeCalls = state.activeCalls.filter((item)=> item !== callName)
        },
    },

    modules: {
        news:            news,
        bookforum:       bookforum,
        lightbox:        lightbox,
        viewport:        viewport,
        user:            user,
        ads:             ads,
        relatedArticles: relatedArticles,
        reCaptcha:       reCaptcha,
        articles:        articles,
        navigation:      navigation,
        login:           login
    },

    plugins: [viewportPlugin, location, keyListeners, shippingCountry, adsPlugin]
});
