<template>
	<div class="global-wrapper">
		<slot></slot>
	</div>
</template>

<script>
	import auth from 'lib/auth';
	import { mapActions, mapMutations, mapState } from 'vuex';
	import MODALS from 'shared/constants/Modals';

	export default {
		name: 'app',
		props: {
			category: String,
			siteSection: String,
			username: String,
			tag: String
		},
		computed: {
			...mapState({
				userIsLoggedIn: state => state.user.userIsLoggedIn
			})
		},
		methods: {
			...mapActions('ads', ['getAds', 'getGalleryAds', 'getInternalAd']),
			...mapActions('user', ['checkLoginStatus']),
			...mapMutations('user', ['setUsername']),
			...mapMutations('navigation', ['setCategory', 'setTag']),
			...mapMutations(['openModal']),
			...mapMutations('viewport', ['setWindowWidth', 'setWindowHeight']),
		},
		mounted: function(){
			this.setCategory(this.category);
			if ( typeof this.tag !== undefined ) this.setTag(this.tag);
			this.setWindowWidth(window.innerWidth);
			this.setWindowHeight(window.innerHeight);
			this.getAds(this.siteSection);
			this.getGalleryAds(this.siteSection);
			this.getInternalAd(this.siteSection);
			
			this.checkLoginStatus();

			if( this.username !== undefined ){
				this.setUsername(this.username);
			}

			this.$nextTick(function(){
				if (process.env.NODE_ENV === 'development'){ console.log('Site is in ' + process.env.NODE_ENV + ' mode.' ) }; 
			});
		}
	};
</script>